import React, { useEffect, useRef, useState } from 'react';
import {
  Html5Qrcode,
  Html5QrcodeCameraScanConfig,
  Html5QrcodeScannerState,
} from 'html5-qrcode';
import styled from 'styled-components';
import './QrScanner.css';

interface QRCodeScannerProps {
  onScan: (decodedText: string) => void;
  onError: (error: string) => void;
  isPaused: boolean;
}

const Wrapper = styled.div<{ isPaused: boolean }>`
  display: ${({ isPaused }) => (isPaused ? 'none' : 'block')};
`;

const QRCodeScanner: React.FC<QRCodeScannerProps> = ({
  onScan,
  onError,
  isPaused,
}) => {
  const [scanning, setScanning] = useState(false);
  const qrCodeScannerRef = useRef<HTMLDivElement | null>(null);
  const html5QrCode = useRef<Html5Qrcode | null>(null);

  const config: Html5QrcodeCameraScanConfig = {
    fps: 20,
  };

  const cameraConfig: MediaTrackConstraints = {
    facingMode: 'environment',
  };

  useEffect(() => {
    const start = async () => {
      try {
        html5QrCode.current = new Html5Qrcode('qrCodeReader');
        await html5QrCode.current.start(cameraConfig, config, onScan, onError);
      } catch (error) {
        console.error('Error starting QR code scan:', error);
      }
    };
    start();

    return () => {
      if (html5QrCode.current) {
        const state = html5QrCode.current.getState();
        if (
          state === Html5QrcodeScannerState.SCANNING ||
          state === Html5QrcodeScannerState.PAUSED
        ) {
          html5QrCode.current
            .stop()
            .then(() => {
              console.log('Stopped');
            })
            .catch((err) => {
              console.error('Failed to stop QR code scan:', err);
            });
        }
      }
    };
  }, []);

  useEffect(() => {
    if (isPaused) {
      if (html5QrCode.current) {
        const state = html5QrCode.current.getState();
        if (!isPaused && state === Html5QrcodeScannerState.SCANNING) {
          html5QrCode.current.pause();
        }
        if (isPaused && state === Html5QrcodeScannerState.PAUSED) {
          html5QrCode.current.pause();
        }
      }
    }
  }, [isPaused]);

  return (
    <Wrapper isPaused={isPaused}>
      <div ref={qrCodeScannerRef} id="qrCodeReader" />
      {scanning && <p>Scanning...</p>}
    </Wrapper>
  );
};

export default QRCodeScanner;
