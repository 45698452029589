import {
  FieldType,
  MultipleSelectFieldType,
  SingleSelectFieldType,
  TextFieldType,
} from './FormTypes';

export enum RequestStatusType {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  ENTER = 'ENTER',
}

export interface ResponseType {
  field_schema_id: number;
  type: FieldType;
}

export interface TextResponseType extends ResponseType {
  text: string;
  type: FieldType.TEXT;
}

export interface SelectableResponseType extends ResponseType {
  options: number[];
  type: FieldType.SINGLE_SELECT | FieldType.MULTIPLE_SELECT;
}

export interface SingleSelectResponseType extends SelectableResponseType {
  type: FieldType.SINGLE_SELECT;
}

export interface MultipleSelectResponseType extends SelectableResponseType {
  type: FieldType.MULTIPLE_SELECT;
}

export type AnyResponseType =
  | TextResponseType
  | SingleSelectResponseType
  | MultipleSelectResponseType;

export interface ResponseKey<T> {
  response: T | null;
}

export type AnyFieldWithResponseType =
  | (TextFieldType & ResponseKey<TextResponseType>)
  | (SingleSelectFieldType & ResponseKey<SingleSelectResponseType>)
  | (MultipleSelectFieldType & ResponseKey<MultipleSelectResponseType>);

export interface RequestType {
  id: number;
  event_id: number;
  email: string;
  create_datetime: string;
  status: RequestStatusType;
  ticket_id: number;
  fields: AnyFieldWithResponseType[];
}
