import React, { useEffect, useState } from 'react';
import Input from '../../../../atoms/Input';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { AxiosRequestConfig } from 'axios';
import { ISearchResult } from '../../../../types/Search';
import Button from '../../../../atoms/Button';
import { useAxios } from '../../../../axiosConfig';
import RequestComponent from '../requests/RequestComponent';
import { RequestType } from '../../../../types/Request';
import { useTicketId } from '../scanner/Ticket';
import { useEventId } from '../../EventId';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeader,
  StyledTableHeaderCell,
  StyledTableRow,
} from '../../../../atoms/Table';
import { FieldTitle } from '../../../../molecules/Texts';

const Wrapper = styled.div`
  display: grid;
  text-align: center;
  gap: 30px;
`;

const SearchView = () => {
  const { eventId } = useParams();
  const [query, setQuery] = useState<string>('');
  const [result, setResult] = useState<ISearchResult[]>([]);
  const axios = useAxios();

  useEffect(() => {
    setQuery('');
    setResult([]);
  }, [eventId]);

  useEffect(() => {
    if (!query) {
      setResult([]);
      return;
    }

    const controller = new AbortController();
    (async () => {
      const config: AxiosRequestConfig = {
        params: {
          email: query,
        },
        signal: controller.signal,
      };
      try {
        const result = await axios.get(`/search/${eventId}`, config);
        setResult(result.data);
      } catch (error) {
        console.error('Failed to fetch search results', error);
      }
    })();

    return () => controller.abort();
  }, [query, eventId]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const [requestId, setRequestId] = useState<number | null>(null);
  const [request, setRequest] = useState<RequestType | null>(null);
  const { ticket, isLoading: isTicketLoading } = useTicketId(
    request?.ticket_id || null
  );
  const { event, isLoading: isEventLoading } = useEventId(
    request?.event_id || null
  );

  useEffect(() => {
    if (!requestId) return;
    (async function () {
      try {
        const response = await axios.get(
          `events/${eventId}/requests/${requestId}`
        );
        setRequest(response.data);
      } catch (error) {
        console.error('Failed to fetch request details', error);
        setRequest(null);
      }
    })();
  }, [requestId, eventId]);

  if (requestId && !isTicketLoading && !isEventLoading && request && event) {
    return (
      <div>
        <FieldTitle>Panel Wyszukiwania Zgłoszeń</FieldTitle>
        <Button onClick={() => setRequestId(null)}>Powrót</Button>
        <RequestComponent request={request} ticket={ticket} event={event} />
      </div>
    );
  }

  return (
    <Wrapper>
      <FieldTitle>Panel Wyszukiwania Zgłoszeń</FieldTitle>
      <Input
        value={query}
        onChange={handleQueryChange}
        placeholder={'Podaj adres email...'}
      />
      {result.length > 0 ? (
        <StyledTable>
          <StyledTableHeader>
            <StyledTableRow>
              <StyledTableHeaderCell>Id</StyledTableHeaderCell>
              <StyledTableHeaderCell>E-mail</StyledTableHeaderCell>
              <StyledTableHeaderCell>Operacja</StyledTableHeaderCell>
            </StyledTableRow>
          </StyledTableHeader>
          {result.map((result) => (
            <StyledTableRow key={result.id}>
              <StyledTableCell>{result.id}</StyledTableCell>
              <StyledTableCell>{result.email}</StyledTableCell>
              <StyledTableCell>
                <Button onClick={() => setRequestId(result.id)}>Sprawdź</Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledTable>
      ) : (
        <p>
          Brak elementów spełniających kryteria lub nie wyszukujesz żadnych
          wyników.
        </p>
      )}
    </Wrapper>
  );
};

export default SearchView;
