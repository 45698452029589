import React, { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL, EventType } from '../util/Api';
import EventCard from '../views/MainView/sections/EventSection/EventCard';
import styled from 'styled-components';
import { SectionDescription } from '../atoms/SectionHeader';
import { useAxios } from '../axiosConfig';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 500px);
  justify-items: stretch;
  align-items: stretch;
  gap: 80px;
`;

const EventCardContainer = () => {
  const [events, setEvents] = useState<EventType[] | null>(null);
  const axios = useAxios();
  useEffect(() => {
    const fetchEvents = async () => {
      const requestBody: AxiosRequestConfig = {
        params: {
          upcoming: true,
          ongoing: true,
          expired: false,
          show_hide: false,
        },
      };
      try {
        const response = await axios.get(BASE_URL + '/events', requestBody);
        setEvents(response.data);
      } catch (error) {
        console.error('Failed to fetch events', error);
      }
    };
    fetchEvents();
  }, []);

  return (
    <Wrapper>
      {events &&
        events.map((event) => <EventCard key={event.id} event={event} />)}

      {!events && (
        <SectionDescription>Pobieranie wydarzeń...</SectionDescription>
      )}

      {events && events.length === 0 && (
        <SectionDescription>
          Obecnie nie mamy żadnych zaplanowanych wydarzeń.
        </SectionDescription>
      )}
    </Wrapper>
  );
};

export default EventCardContainer;
