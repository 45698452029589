import styled from 'styled-components';
import React from 'react';

interface CloseMenuButtonProps {
  setOpen: (value: boolean) => void;
}

const Button = styled.button`
  display: block;
  background: transparent;
  border: none;
  width: 100%;
  height: 70px;
  padding: 15px 0;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.03);
    opacity: 0.8;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.9;
  }
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const CloseMenuButton: React.FC<CloseMenuButtonProps> = ({ setOpen }) => {
  return (
    <Button onClick={() => setOpen(false)}>
      <Icon src={'/svg/cancel.svg'} alt={'Close menu button'} />
    </Button>
  );
};

export default CloseMenuButton;
