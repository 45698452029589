import styled from 'styled-components';
import React, { useState } from 'react';

const Button = styled.button`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  padding: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.quaternary};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkblue};
  cursor: pointer;
`;

interface IsOpenProps {
  isOpen: boolean;
}

const Icon = styled.img<IsOpenProps>`
  margin-left: auto;
  transition: all 0.3s ease;
  transform: ${({ isOpen }) => (!isOpen ? 'rotate(-90deg)' : 'none')};
`;

interface NavigationButtonProps {
  children: React.ReactNode;
  label: string;
  initialState?: boolean;
  isFocused?: boolean;
}

const Label = styled.p<{ isFocused: boolean }>`
  font-weight: ${({ isFocused }) => (isFocused ? 'bold' : 'normal')};
  color: ${({ theme, isFocused }) =>
    isFocused ? theme.colors.primary : theme.colors.text};
  text-align: start;
`;

const Dropdown: React.FC<NavigationButtonProps> = ({
  label,
  children,
  initialState = false,
  isFocused = false,
}) => {
  const [isOpen, setOpen] = useState<boolean>(initialState);
  const changeOpen = () => setOpen((prevState) => !prevState);

  return (
    <div>
      <>
        <Button onClick={() => changeOpen()}>
          <Label isFocused={isFocused}>{label}</Label>
          <Icon isOpen={isOpen} src={'/svg/dropdown.svg'} />
        </Button>
        {isOpen && <div>{children}</div>}
      </>
    </div>
  );
};

export default Dropdown;
