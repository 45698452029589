import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  display: block;
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.quaternary};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  padding: 10px;

  border-radius: 4px;
  line-height: 1.2;
  resize: none; /* Blokuje możliwość ręcznego skalowania */
  overflow-y: hidden; /* Ukrywa pionowy pasek przewijania */
  height: auto;
  min-height: 1rem; /* Wysokość odpowiadająca jednej linijce tekstu */
  max-height: 300px; /* Opcjonalnie: Maksymalna wysokość pola */
  box-sizing: border-box; /* Zapewnia prawidłowe rozmiary pola */

  transition: all 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: 0 0 12px ${({ theme }) => theme.colors.primary};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.textLightGray};
    opacity: 0.6;
  }

  -moz-appearance: textfield; /* For Firefox */

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default Input;
