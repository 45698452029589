import styled from 'styled-components';

interface SectionProps {
  bgColor: string;
}

const Section = styled.section<SectionProps>`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};

  //position: relative;
  //z-index: -10;
`;

export default Section;
