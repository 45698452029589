export function format(date: Date) {
  const formater = new Intl.DateTimeFormat('pl-PL', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

  const parts = formater.formatToParts(date);
  const day = parts.find((part) => part.type === 'day')?.value;
  const month = parts.find((part) => part.type === 'month')?.value;
  const year = parts.find((part) => part.type === 'year')?.value;
  const time =
    parts.find((part) => part.type === 'hour')?.value +
    ':' +
    parts.find((part) => part.type === 'minute')?.value;

  return `${day} ${month} ${year} r. o godzinie ${time}`;
}
