import React, { useRef, useEffect, HTMLInputTypeAttribute } from 'react';
import Input from '../../../atoms/Input';
import styled from 'styled-components';
import Element from '../Element';
import FieldTitleDescription from './FieldTitleDescription';

interface BonusFieldProps {
  value: string;
  onChange: (text: string) => void;
  title: string;
  description: string;
  required: boolean;
  type: HTMLInputTypeAttribute;
  placeholder: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const BonusField: React.FC<BonusFieldProps> = ({
  title,
  description,
  required,
  value,
  onChange,
  type,
  placeholder,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <Element>
      <FieldTitleDescription
        title={title}
        description={description}
        required={required}
      />
      <Wrapper>
        <Input
          required={true}
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
        />
      </Wrapper>
    </Element>
  );
};

export default BonusField;
