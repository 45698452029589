import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL, EventType, PutEventType } from '../../util/Api';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { RootState } from '../store';
import {
  AnyFieldType,
  FieldType,
  FormType,
  PutFieldType,
  PutOptionType,
} from '../../types/FormTypes';

interface FormState {
  form: FormType | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: FormState = {
  form: null,
  status: 'idle',
};

export const fetchForm = createAsyncThunk<
  FormType,
  { axios: AxiosInstance; formId: number },
  { rejectValue: string }
>('data/fetchForm', async ({ axios, formId }) => {
  const response = await axios.get<FormType>(`/forms/${formId}`);
  return response.data;
});

export const createField = createAsyncThunk<
  AnyFieldType,
  { axios: AxiosInstance; formId: number; type: FieldType },
  { rejectValue: string }
>('data/createField', async ({ axios, formId, type }) => {
  const response = await axios.post<AnyFieldType>(`/forms/${formId}/fields`, {
    type,
  });
  return response.data;
});

export const deleteField = createAsyncThunk<
  number,
  { axios: AxiosInstance; formId: number; fieldId: number },
  { rejectValue: string }
>(
  'data/deleteField',
  async ({ axios, formId, fieldId }, { rejectWithValue }) => {
    try {
      await axios.delete<AnyFieldType>(`/forms/${formId}/fields/${fieldId}`);
      return fieldId;
    } catch (error) {
      return rejectWithValue('error');
    }
  }
);

export const putField = createAsyncThunk<
  AnyFieldType,
  {
    axios: AxiosInstance;
    formId: number;
    fieldId: number;
    updates: PutFieldType;
  },
  { rejectValue: string }
>(
  'data/putField',
  async ({ axios, formId, fieldId, updates }, { rejectWithValue }) => {
    try {
      const response = await axios.put<AnyFieldType>(
        `/forms/${formId}/fields/${fieldId}`,
        updates
      );
      return response.data;
    } catch (error) {
      return rejectWithValue('error');
    }
  }
);

export const createOption = createAsyncThunk<
  AnyFieldType,
  { axios: AxiosInstance; formId: number; fieldId: number },
  { rejectValue: string }
>('data/createOption', async ({ axios, formId, fieldId }) => {
  const response = await axios.post<AnyFieldType>(
    `/forms/${formId}/fields/${fieldId}/options`
  );
  return response.data;
});

export const deleteOption = createAsyncThunk<
  AnyFieldType,
  { axios: AxiosInstance; formId: number; fieldId: number; optionId: number },
  { rejectValue: string }
>(
  'data/deleteOption',
  async ({ axios, formId, fieldId, optionId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete<AnyFieldType>(
        `/forms/${formId}/fields/${fieldId}/options/${optionId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue('error');
    }
  }
);

export const putOption = createAsyncThunk<
  AnyFieldType,
  {
    axios: AxiosInstance;
    formId: number;
    fieldId: number;
    optionId: number;
    updates: PutOptionType;
  },
  { rejectValue: string }
>('data/putOption', async ({ axios, formId, fieldId, optionId, updates }) => {
  const response = await axios.put<AnyFieldType>(
    `/forms/${formId}/fields/${fieldId}/options/${optionId}`,
    updates
  );
  return response.data;
});

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchForm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchForm.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.form = action.payload;
      })
      .addCase(fetchForm.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createField.fulfilled, (state, action) => {
        if (state.form) state.form.fields.push(action.payload);
      })
      .addCase(putField.fulfilled, (state, action) => {
        if (state.form)
          state.form.fields = state.form.fields.map((field) =>
            field.id !== action.payload.id ? field : action.payload
          );
      })
      .addCase(deleteField.fulfilled, (state, action) => {
        if (state.form)
          state.form.fields = state.form.fields.filter(
            (field) => field.id !== action.payload
          );
      })

      .addCase(createOption.fulfilled, (state, action) => {
        if (state.form)
          state.form.fields = state.form.fields.map((field) =>
            field.id !== action.payload.id ? field : action.payload
          );
      })
      .addCase(putOption.fulfilled, (state, action) => {
        if (state.form)
          state.form.fields = state.form.fields.map((field) =>
            field.id !== action.payload.id ? field : action.payload
          );
      })
      .addCase(deleteOption.fulfilled, (state, action) => {
        if (state.form)
          state.form.fields = state.form.fields.map((field) =>
            field.id !== action.payload.id ? field : action.payload
          );
      });
  },
});

export default formSlice.reducer;
export const selectForm = (state: RootState) => state.form.form;
export const selectFormStatus = (state: RootState) => state.form.status;
