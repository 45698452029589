import React from 'react';
import { useAxios } from '../../../../axiosConfig';
import Button from '../../../../atoms/Button';

interface IExcelButton {
  eventId: number;
  title: string;
}

const ExcelButton: React.FC<IExcelButton> = ({ eventId, title }) => {
  const axios = useAxios();

  const handleDownload = async () => {
    try {
      const response = await axios.get(`/events/${eventId}/excel`, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.data.type });

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = title.replaceAll(' ', '-') + '.xlsx';

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Błąd podczas pobierania pliku', error);
    }
  };

  return (
    <Button onClick={handleDownload}>
      <img src={'/svg/table.svg'} alt={'Pobierz w formacie Excel'} />
      <p>Wygeneruj Arkusz</p>
    </Button>
  );
};

export default ExcelButton;
