import styled from 'styled-components';
import PropTypes from 'prop-types';

interface MarginProps {
  vertical?: string;
  horizontal?: string;
}

const Margin = styled.div<MarginProps>`
  padding: 0;
  margin: ${({ vertical = '0', horizontal = '0' }) =>
      vertical + ' ' + horizontal}
    0 0;
`;

export default Margin;
