import React from 'react';
import EventForm from '../../../EventView/Form/EventForm';
import AcceptReject from '../../AcceptReject';
import { TicketStatus, TicketType } from '../../../../types/Ticket';
import Ticket from '../scanner/Ticket';
import { EventType } from '../../../../util/Api';
import { RequestType } from '../../../../types/Request';
import { useAxios } from '../../../../axiosConfig';

export interface IRequestProps {
  ticket: TicketType | null;
  event: EventType;
  request: RequestType;
  onAcceptForm?: (requestId: number) => void;
  onRejectForm?: (requestId: number) => void;
  onEntry?: (requestId: number) => void;
  onDelete?: (requestId: number) => void;
}

const RequestComponent: React.FC<IRequestProps> = ({
  ticket,
  event,
  request,
  onAcceptForm,
  onRejectForm,
  onEntry,
  onDelete,
}) => {
  const axios = useAxios();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleAcceptForm = async (requestId: number) => {
    try {
      await axios.post(`/events/${event.id}/requests/${request.id}/accept`);
      scrollToTop();
      if (onAcceptForm) {
        onAcceptForm(requestId);
      }
    } catch (error) {
      console.error('Failed to accept request', error);
    }
  };

  const handleRejectForm = async (requestId: number) => {
    try {
      await axios.post(`/events/${event.id}/requests/${request.id}/reject`);
      scrollToTop();
      if (onRejectForm) {
        onRejectForm(requestId);
      }
    } catch (error) {
      console.error('Failed to accept request', error);
    }
  };

  const handleCheckin = async (ticketId: number) => {
    try {
      await axios.post(`/tickets/${ticketId}/checkin`);
      scrollToTop();
      if (onEntry) {
        onEntry(ticketId);
      }
    } catch (error) {
      console.error('Failed to accept request', error);
    }
  };

  const handleDelete = async (requestId: number) => {
    try {
      await axios.delete(`/events/${event.id}/requests/${requestId}/delete`);
      scrollToTop();
      if (onDelete) {
        onDelete(requestId);
      }
    } catch (error) {
      console.error('Failed to accept request', error);
    }
  };

  return (
    <div>
      {ticket && <Ticket ticket={ticket} />}
      <EventForm event={event} form={request} editable={false} />

      {!ticket && (
        <AcceptReject
          accept={() => handleAcceptForm(request.id)}
          reject={() => handleRejectForm(request.id)}
        />
      )}

      {ticket && ticket.status === TicketStatus.UNUSED && (
        <AcceptReject
          acceptLabel={'Wpuść osobę'}
          rejectLabel={'Usuń osobę'}
          accept={() => handleCheckin(ticket.id)}
          reject={() => handleDelete(request.id)}
        />
      )}
    </div>
  );
};

export default RequestComponent;
