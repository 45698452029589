import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface AdminNavigateButtonProps {
  label: string;
  src: string;
  to: string;
}

const Link = styled(NavLink)`
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  padding: 10px 10px 10px 25px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.quaternary};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkblue};
  gap: 15px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &.active {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const AdminNavigateButton: React.FC<AdminNavigateButtonProps> = ({
  label,
  src,
  to,
}) => {
  return (
    <Link to={to}>
      <>
        <img src={src} alt={label} />
        <p>{label}</p>
      </>
    </Link>
  );
};

export default AdminNavigateButton;
