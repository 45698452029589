import React, { createContext, ReactNode, useState } from 'react';
import theme from './theme';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import defaultFont from './fonts/defaultFont';
import defaultColor from './colors/defaultColor';
import mediumFont from './fonts/mediumFont';
import largeFont from './fonts/largeFont';
import blackWhiteColor from './colors/blackWhiteColor';
import blackYellowColor from './colors/blackYellowColor';

interface ThemeContextProps {
  setColorOption: (color: ColorOptions) => void;
  setFontOption: (font: FontOptions) => void;
  fontOption: FontOptions;
  colorOption: ColorOptions;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

interface ThemeContextProviderProps {
  children: ReactNode;
}

export enum FontOptions {
  DEFAULT = 'DEFAULT',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum ColorOptions {
  DEFAULT = 'DEFAULT',
  BLACK_WHITE = 'BLACK_WHITE',
  BLACK_YELLOW = 'BLACK_YELLOW',
}

const ThemeContextProvider = ({ children }: ThemeContextProviderProps) => {
  const [isAccessibleTheme, setIsAccessibleTheme] = useState(false);

  const [fontOption, setFontOption] = useState<FontOptions>(
    FontOptions.DEFAULT
  );

  const [colorOption, setColorOption] = useState<ColorOptions>(
    ColorOptions.DEFAULT
  );

  const chooseFont = (option: FontOptions) => {
    switch (option) {
      case FontOptions.DEFAULT:
        return defaultFont;
      case FontOptions.MEDIUM:
        return mediumFont;
      case FontOptions.LARGE:
        return largeFont;
    }
  };

  const chooseColor = (option: ColorOptions) => {
    switch (option) {
      case ColorOptions.DEFAULT:
        return defaultColor;
      case ColorOptions.BLACK_WHITE:
        return blackWhiteColor;
      case ColorOptions.BLACK_YELLOW:
        return blackYellowColor;
    }
  };

  const currentTheme = {
    fonts: chooseFont(fontOption),
    colors: chooseColor(colorOption),
  };

  return (
    <ThemeContext.Provider
      value={{ setColorOption, setFontOption, fontOption, colorOption }}
    >
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
export { ThemeContext };
