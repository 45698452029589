import styled from 'styled-components';
import React from 'react';
import { Link } from './Link';

interface MediaItemProps {
  size: string;
  href: string;
  alt: string;
  src: string;
  gray?: boolean;
}

const Image = styled.img<{ $size: string; $gray: boolean }>`
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  object-fit: cover;
  display: block;
  filter: ${({ $gray }) => ($gray ? 'invert(0.7)' : 'none')};
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const Media: React.FC<MediaItemProps> = ({
  size,
  href,
  src,
  alt,
  gray = false,
}) => {
  return (
    <Link href={href} target={'_blank'}>
      <Image $gray={gray} $size={size} src={src} alt={alt} />
    </Link>
  );
};

export default Media;
