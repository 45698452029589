import styled from 'styled-components';
import React from 'react';
import { EventType } from '../../util/Api';
import Element from './Element';
import Margin from '../../atoms/Margin';
import ClickableText from '../../atoms/ClickableParagraph';

const Wrapper = styled.div``;

interface EventDetailProps {
  event: EventType;
}

const Title = styled.p`
  font-size: ${({ theme }) => theme.fonts.big};
  font-weight: bold;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.text};
  white-space: pre-wrap;
`;

const EventDetail: React.FC<EventDetailProps> = ({ event }) => {
  return (
    <Element>
      <Wrapper>
        <Title>{event.title}</Title>
        <Margin vertical={'30px'} />
        <Description>
          <ClickableText text={event.description} />
        </Description>
      </Wrapper>
    </Element>
  );
};

export default EventDetail;
