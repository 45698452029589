import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { TicketStatus, TicketType } from '../../../../types/Ticket';
import Element from '../../../EventView/Element';
import { FieldTitle, KeyText, ValueText } from '../../../../molecules/Texts';
import { format } from '../../../../js/DatetimeFormat';
import { SpaceRowWrapper } from '../../../../molecules/Wrappers';
import { useAxios } from '../../../../axiosConfig';

export const useTicketId = (ticketId: number | null) => {
  const [ticket, setTicket] = useState<TicketType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const axios = useAxios();

  useEffect(() => {
    setIsLoading(true);

    if (!ticketId) {
      setTicket(null);
      setIsLoading(false);
      return;
    }

    // fetch ticket
    (async function fetchTicket() {
      try {
        const response = await axios.get(`/tickets/${ticketId}`);
        setTicket(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch ticket');
        setTicket(null);
      }
    })();

    return () => {
      setTicket(null);
    };
  }, [ticketId]);
  return { ticket, isLoading };
};

interface TicketProps {
  ticket: TicketType;
}

const ColorStatus = styled.div<{ status: TicketStatus }>`
  border-bottom: 2px solid
    ${({ theme, status }) =>
      status === TicketStatus.UNUSED
        ? theme.colors.success
        : theme.colors.danger};
`;

const Ticket: React.FC<TicketProps> = ({ ticket }) => {
  if (!ticket) return <Element>Ładowanie...</Element>;

  const createDate = new Date(ticket.create_datetime);
  const parsedCreateDate = format(createDate);

  const parsedScanDate = ticket.scan_datetime
    ? format(new Date(ticket.scan_datetime))
    : 'NIGDY';

  const scannerUser = ticket.scanner?.name || 'NIKT';

  return (
    <Element>
      <FieldTitle>
        <img src={'/svg/ticket.svg'} alt={'ikona biletu'} />
        <ColorStatus status={ticket.status}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>Bilet na "{ticket.event.title}"</p>
        </ColorStatus>
      </FieldTitle>

      <Element>
        <SpaceRowWrapper>
          <img src={'/svg/calendar.svg'} alt={'ikona kalendarza'} />
          <KeyText>
            Data zaakceptowania biletu: <ValueText>{parsedScanDate}</ValueText>
          </KeyText>
        </SpaceRowWrapper>

        <SpaceRowWrapper>
          <img src={'/svg/user.svg'} alt={'ikona użytkownika'} />
          <KeyText>
            Bilet zaakceptowany przez: <ValueText>{scannerUser}</ValueText>
          </KeyText>
        </SpaceRowWrapper>
      </Element>
      <Element>
        <SpaceRowWrapper>
          <img src={'/svg/calendar.svg'} alt={'ikona kalendarza'} />
          <KeyText>
            Data utworzenia biletu: <ValueText>{parsedCreateDate}</ValueText>
          </KeyText>
        </SpaceRowWrapper>

        <SpaceRowWrapper>
          <img src={'/svg/user.svg'} alt={'ikona użytkownika'} />
          <KeyText>
            Bilet wystawiony przez: <ValueText>{ticket.creator.name}</ValueText>
          </KeyText>
        </SpaceRowWrapper>
      </Element>
    </Element>
  );
};

export default Ticket;
