import styled from 'styled-components';
import React from 'react';
import NavItem from './NavItem';
import Logo from '../../atoms/Logo';
import CloseMenuButton from '../CloseMenuButton';
// import LinkedinButton from './media/LinkedinButton';
// import FacebookButton from './media/FacebookButton';
// import InstagramButton from './media/InstagramButton';

interface MobileMenuProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(32px);
  z-index: 999;
  display: grid;
  grid-template-rows: 2fr 4fr 1fr;
  justify-items: center;
`;

const NavigationList = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoWrapper = styled.div`
  align-self: center;
  width: 30%;
  max-width: 180px;
`;

const ListItem = styled.li`
  display: block;
  width: 100%;
`;

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, setOpen }) => {
  if (!isOpen) return null;

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <NavigationList>
        <ListItem>
          <CloseMenuButton setOpen={setOpen} />
        </ListItem>
        <ListItem>
          <NavItem mobile={true} to={'/'}>
            Strona Główna
          </NavItem>
        </ListItem>
        <ListItem>
          <NavItem mobile={true} to={'/about'}>
            O nas
          </NavItem>
        </ListItem>
      </NavigationList>
    </Wrapper>
  );
};

export default MobileMenu;
