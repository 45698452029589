import styled from 'styled-components';

const GradientSection = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.secondary};
  background: linear-gradient(
    168deg,
    ${({ theme }) => theme.colors.secondary} 0%,
    ${({ theme }) => theme.colors.secondary} 33%,
    ${({ theme }) => theme.colors.tertiary} 100%
  );

  -webkit-box-shadow: 0px 0px 53px 20px ${({ theme }) => theme.colors.tertiary};
  -moz-box-shadow: 0px 0px 53px 20px ${({ theme }) => theme.colors.tertiary};
  box-shadow: 0px 0px 53px 20px ${({ theme }) => theme.colors.tertiary};
`;

export default GradientSection;
