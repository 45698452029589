import React from 'react';
import styled, { keyframes } from 'styled-components';

const subtleRotation = keyframes`
    0% {
        transform: rotate(0deg) scale(1);
    }
    25% {
        transform: rotate(1.5deg) scale(1.05);
    }
    50% {
        transform: rotate(0deg) scale(1);
    }
    75% {
        transform: rotate(-1.5deg) scale(0.95);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
`;

const Img = styled.img`
  animation: ${subtleRotation} 10s ease-in-out infinite;
  width: 80%;
  &:hover {
    animation-play-state: paused;
  }
`;

const OpeningImage = () => {
  return (
    <Img
      src={'/img/opening_image.png'}
      alt={'Grafika promująca wydarzenie „Strategiczne Spotkania w Świecie Biznesu”. Na grafice znajdują się informacje o dacie wydarzenia: 25-27 listopada 2024, miejscu: Uniwersytet Ekonomiczny w Krakowie oraz tematyce wydarzenia: doradztwo, strategia, technologia. W tle widoczna jest sieć połączeń. Kolory dominujące to fioletowy i biały.'}
      className={'animate right-animate'}
    />
  );
};

export default OpeningImage;
