import React from 'react';
import { FieldType, FormType } from '../../../../types/FormTypes';
import Button from '../../../../atoms/Button';
import { createField } from '../../../../state/forms/formSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../state/store';
import { useAxios } from '../../../../axiosConfig';

interface CreateFieldsProps {
  form: FormType;
}

const CreateFields: React.FC<CreateFieldsProps> = ({ form }) => {
  const dispatch: AppDispatch = useDispatch();
  const axios = useAxios();
  const formId = form.id;

  return (
    <div>
      <Button
        color={'success'}
        onClick={() =>
          dispatch(createField({ axios, formId, type: FieldType.TEXT }))
        }
      >
        <>
          <img src={'/svg/draw.svg'} alt={'text field icon'} />
          <p>Tekstowe</p>
        </>
      </Button>
      <Button
        color={'success'}
        onClick={() =>
          dispatch(
            createField({
              axios,
              formId,
              type: FieldType.SINGLE_SELECT,
            })
          )
        }
      >
        <>
          <img src={'/svg/radio.svg'} alt={'single select icon'} />
          <p>Zaznaczenie (pojedyncze)</p>
        </>
      </Button>
      <Button
        color={'success'}
        onClick={() =>
          dispatch(
            createField({
              axios,
              formId,
              type: FieldType.MULTIPLE_SELECT,
            })
          )
        }
      >
        <>
          <img src={'/svg/checkbox.svg'} alt={'multiple select icon'} />
          <p>Zaznaczenie (wielokrotne)</p>
        </>
      </Button>
    </div>
  );
};

export default CreateFields;
