import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

interface SectionHeader {
  title: string;
  description?: string;
}

const TextWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
`;

export const SectionTitle = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.big};
  color: ${({ theme }) => theme.colors.text};
  margin: 0 auto;
  text-align: center;
`;

export const SectionDescription = styled.p`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.textLightGray};
  margin: 0 auto;
  text-align: center;
`;

const TextHeader: React.FC<SectionHeader> = ({ title, description }) => {
  return (
    <TextWrapper className={'animate left-animate'}>
      <SectionTitle>{title}</SectionTitle>
      {description && <SectionDescription>{description}</SectionDescription>}
    </TextWrapper>
  );
};

TextHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TextHeader;
