import React from 'react';
import Element from '../views/EventView/Element';
import { SpaceRowWrapper } from '../molecules/Wrappers';
import { KeyText, ValueText } from '../molecules/Texts';

const ElementAlert: React.FC<{ header: string; value: string }> = ({
  header,
  value,
}) => {
  return (
    <Element>
      <SpaceRowWrapper>
        <img src={'/svg/info.svg'} alt="ikona informacji" />
        <KeyText>
          {header}
          <ValueText>{value}</ValueText>
        </KeyText>
      </SpaceRowWrapper>
    </Element>
  );
};

export default ElementAlert;
