import React, { useContext, useState } from 'react';
import styled from 'styled-components';

const NotificationWrapper = styled.div`
  position: fixed;
  top: 5%;
  right: 5%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 999999;
`;

const AlertWrapper = styled.div`
  padding: 15px;

  background-color: ${({ theme }) => theme.colors.danger};
  border: 1px solid ${({ theme }) => theme.colors.danger};
  backdrop-filter: opacity(0.5);
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text};
`;

export const useNotification = () => {
  const notification = useContext(NotificationContext);
  if (!notification) throw new Error('NotificationContext is not provided');
  return notification;
};

export const NotificationContext =
  React.createContext<INotificationContext | null>(null);

export interface INotificationContext {
  showNotification: (message: string, type?: NotificationType) => void;
}

interface NotificationProviderProps {
  children: React.ReactNode;
}

interface INotification {
  message: string;
  id: number;
  type: NotificationType;
}

export enum NotificationType {
  SUCCESS,
  WARNING,
  ERROR,
  INFO,
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [alerts, setAlerts] = useState<INotification[]>([]);

  const showNotification = (
    message: string,
    type: NotificationType = NotificationType.INFO
  ) => {
    const id = Math.random() * Number.MAX_VALUE;
    setAlerts((prev) => [
      ...prev,
      {
        id,
        message,
        type,
      },
    ]);
    setTimeout(() => {
      setAlerts((prev) => prev.filter((e) => e.id !== id));
    }, 5000);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      <NotificationWrapper>
        {alerts.map((alert, index) => (
          <AlertWrapper key={index}>{alert.message}</AlertWrapper>
        ))}
      </NotificationWrapper>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
