import styled from 'styled-components';
import React from 'react';

interface ProgressBarProps {
  percentage: number;
}

const Wrapper = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.text};
`;

const Bar = styled.div<ProgressBarProps>`
  width: ${({ percentage }) => 100 * percentage}%;
  height: 8px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: width 0.3s ease-in-out;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  return (
    <Wrapper>
      <Bar percentage={percentage} />
    </Wrapper>
  );
};

export default ProgressBar;
