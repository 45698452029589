import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, EventType } from '../../util/Api';
import ContentSection from '../../atoms/sections/ContentSection';
import NavBar from '../../molecules/navigations/NavBar';
import EventBanner from './EventBanner';
import EventDetail from './EventDetail';
import EventInfo from './EventInfo';
import EventForm from './Form/EventForm';
import { FieldType, FormType, ResponseType } from '../../types/FormTypes';
import Animate from '../../js/Animate';
import Loading from '../../modals/Loading';
import MessageModal from '../../modals/MessageModal';
import CheckEmailModal from '../../modals/CheckEmailModal';
import ElementAlert from '../../atoms/ElementAlert';
import styled from 'styled-components';

const Blur = styled.div`
  filter: grayscale(100%) brightness(70%);
  pointer-events: none;
  opacity: 0.6;
`;

const EventView = () => {
  const params = useParams();
  const eventId = params.eventId;
  const navigate = useNavigate();

  const [event, setEvent] = useState<EventType | null>(null);
  const [form, setForm] = useState<FormType | null>(null);
  const [fetched, setFetched] = useState<boolean>(false);
  const [response, setResponse] = useState<ResponseType[]>([]);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const responseEvent = await axios.get(`${BASE_URL}/events/${eventId}`);
        const responseForm = await axios.get(`${BASE_URL}/forms/${eventId}`);
        setEvent(responseEvent.data);
        setForm(responseForm.data);
        setFetched(true);
      } catch (error) {
        console.error('Failed to fetch events', error);
        setFetched(true);
      }
    };
    fetchEvent();
  }, []);

  if (fetched && !event && !form) navigate('/');
  if (!event || !form) return null;

  return (
    <ContentSection>
      <Animate />
      <NavBar />
      <EventBanner
        src={BASE_URL + event.banner_url}
        alt={'Baner wydarzenia ' + event.title}
      />
      <EventDetail event={event} />
      <EventInfo event={event} />

      {!form.canJoin.status && (
        <ElementAlert
          header={'Składanie wniosków zostało wyłączone z powodu:'}
          value={form.canJoin.message}
        />
      )}

      <EventForm event={event} form={form} disabled={!form.canJoin.status} />
    </ContentSection>
  );
};

export default EventView;
