import React, { useEffect, useState } from 'react';
import QRScanner from './QRScanner';
import { TicketType } from '../../../../types/Ticket';
import { useAuth } from '../../../../AuthContext';
import Button from '../../../../atoms/Button';
import { useAxios } from '../../../../axiosConfig';
import RequestComponent from '../requests/RequestComponent';
import Element from '../../../EventView/Element';
import { FieldTitle } from '../../../../molecules/Texts';

enum ScanStatus {
  SCANNING,
  FETCHING,
  SUCCESS,
  ERROR,
}

const ScannerView = () => {
  const { token } = useAuth();
  const [uuid, setUuid] = useState<string | null>(null);
  const [ticket, setTicket] = useState<TicketType | null>(null);
  const [scanStatus, setScanStatus] = useState<ScanStatus>(ScanStatus.SCANNING);
  const axios = useAxios();

  const backToScanning = () => {
    setUuid(null);
    setTicket(null);
    setScanStatus(ScanStatus.SCANNING);
  };

  useEffect(() => {
    if (!uuid) return;

    const fetchTicket = async () => {
      setScanStatus(ScanStatus.FETCHING);
      try {
        const response = await axios.get(`/tickets/${uuid}`);
        setScanStatus(ScanStatus.SUCCESS);
        setTicket(response.data);
      } catch (error) {
        setScanStatus(ScanStatus.ERROR);
        setTicket(null);
      }
    };
    fetchTicket();
  }, [uuid, token]);

  return (
    <div>
      <FieldTitle>Panel Skanowania Kodów QR</FieldTitle>
      <QRScanner
        isPaused={!!uuid}
        onScan={(decodedText) => setUuid(decodedText)}
        onError={() => {}}
      />

      {scanStatus !== ScanStatus.SCANNING && (
        <Button onClick={backToScanning}>Powrot do skanowania</Button>
      )}

      {(scanStatus === ScanStatus.ERROR ||
        (scanStatus === ScanStatus.SUCCESS && !ticket)) && (
        <Element>Bilet o podanym kodzie nie istnieje w systemie.</Element>
      )}
      {scanStatus === ScanStatus.SUCCESS && ticket && (
        <RequestComponent
          request={ticket.response}
          ticket={ticket}
          event={ticket.event}
          onEntry={backToScanning}
        />
      )}
    </div>
  );
};

export default ScannerView;
