import React, { useEffect, useState } from 'react';
import { Pagination } from '../../../../types/Pagination';
import { RequestStatusType, RequestType } from '../../../../types/Request';
import Button from '../../../../atoms/Button';
import ProgressBar from '../../../../atoms/ProgressBar';
import Element from '../../../EventView/Element';
import Margin from '../../../../atoms/Margin';
import Loading from '../../../../modals/Loading';
import styled from 'styled-components';
import { useAxios } from '../../../../axiosConfig';

interface RequestsPaginationProps {
  setCurrentRequest: (currentRequest: RequestType | null) => void;
  status: RequestStatusType;
  eventId: number;
  update?: number;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const RequestsPagination: React.FC<RequestsPaginationProps> = ({
  setCurrentRequest,
  status,
  eventId,
  update = 1,
}) => {
  const [currentElement, setCurrentElement] = useState<number>(1);
  const [currentPaginationIndex, setCurrentPaginationIndex] =
    useState<number>(0);
  const [currentPaginationPage, setCurrentPaginationPage] = useState<number>(1);
  const [paginationResponseData, setPaginationResponseData] =
    useState<Pagination<RequestType> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const axios = useAxios();

  useEffect(() => {
    const page = Math.max(Math.floor((currentElement - 1) / 12) + 1, 1);
    if (page !== currentPaginationPage) {
      setCurrentPaginationPage(page);
    }
    const index = (currentElement - 1) % 12;
    setCurrentPaginationIndex(index);
  }, [eventId, status, currentElement, update]);

  const fetchPageForms = async () => {
    setIsLoading(true);
    const requestOptions = {
      params: {
        status: status,
        page: currentPaginationPage,
      },
    };
    try {
      const response = await axios.get(
        `/events/${eventId}/requests`,
        requestOptions
      );
      const paginationForms: Pagination<RequestType> = response.data;
      setPaginationResponseData(paginationForms);
      if (currentElement > paginationForms.total_element_count) {
        setCurrentElement(paginationForms.total_element_count);
      }
      if (currentElement < 1 && paginationForms.total_element_count > 0) {
        setCurrentElement(1);
      }
    } catch (error) {
      console.error('Failed to fetch forms', error);
    } finally {
      setIsLoading(false);
    }
  };

  // api fetch data
  useEffect(() => {
    fetchPageForms();
  }, [eventId, status, currentPaginationPage, update]);

  const nextElement = () => {
    if (paginationResponseData && paginationResponseData.total_element_count) {
      const endElement = paginationResponseData.total_element_count;
      setCurrentElement((prevState) => Math.min(endElement, prevState + 1));
    }
  };
  const previousElement = () => {
    if (
      paginationResponseData &&
      paginationResponseData.total_element_count !== 0
    ) {
      setCurrentElement((prevState) => Math.max(1, prevState - 1));
    }
  };

  const currentPaginationData = paginationResponseData
    ? paginationResponseData.data
    : [];
  const currentResponse = currentPaginationData[currentPaginationIndex] || null;
  setCurrentRequest(currentResponse);

  const totalElementCount = paginationResponseData
    ? paginationResponseData.total_element_count
    : 0;

  const percentage = currentElement / totalElementCount;

  return (
    <div>
      {isLoading && <Loading />}
      <Element>
        {totalElementCount ? (
          <div>
            <p>
              Wyświetlany {currentElement} z {totalElementCount}
            </p>
            <ProgressBar percentage={percentage} />
          </div>
        ) : (
          <p>Brak dostępnych elementów do wyświetlenia.</p>
        )}
        <Margin vertical={'14px'} />
        <Wrapper>
          <Button onClick={() => previousElement()}>
            <>
              <img src={'/svg/arrow-left.svg'} alt={'left arrow'} />
              <p>Poprzedni</p>
            </>
          </Button>
          <Button onClick={() => fetchPageForms()}>
            <img src={'/svg/sync.svg'} alt={'left arrow'} />
          </Button>
          <Button onClick={() => nextElement()}>
            <>
              <p>Następny</p>
              <img src={'/svg/arrow-right.svg'} alt={'right arrow'} />
            </>
          </Button>
        </Wrapper>
      </Element>
    </div>
  );
};

export default RequestsPagination;
