import React from 'react';
import { EventType } from '../../../../util/Api';
import Element from '../../../EventView/Element';
import FieldTitleDescription from '../../../EventView/Form/FieldTitleDescription';
import EditableTextInput from './editable/EditableTextInput';
import { putEvent } from '../../../../state/events/eventsSlice';
import ToggleButton from './ToogleButton';
import { AppDispatch, RootState } from '../../../../state/store';
import { useDispatch } from 'react-redux';
import EditableTextArea from './editable/EditableTextArea';
import EditableNumberInput from './editable/EditableNumberInput';
import EditableDateInput from './editable/EditableDateInput';
import EditableElement from './editable/EditableElement';
import { FieldTitle } from '../../../../molecules/Texts';
import EditableImageInput from './editable/EditableImageInput';
import { AxiosInstance } from 'axios';
import { useAxios } from '../../../../axiosConfig';

interface EventSettingsProps {
  event: EventType;
}

const uploadImage = async (
  axios: AxiosInstance,
  image: File
): Promise<string | null> => {
  const formData = new FormData();
  formData.append('image', image);
  try {
    const response = await axios.post('/images', formData);
    return response.data.path;
  } catch {
    return null;
  }
};

const EventSettings: React.FC<EventSettingsProps> = ({ event }) => {
  const dispatch: AppDispatch = useDispatch();
  const axios = useAxios();
  const eventId = event.id;

  return (
    <Element>
      <EditableElement>
        <FieldTitle>Tytuł</FieldTitle>
        <EditableTextInput
          value={event.title}
          onSubmit={(text) =>
            dispatch(putEvent({ axios, eventId, updates: { title: text } }))
          }
        />
      </EditableElement>
      <EditableElement>
        <FieldTitle>Opis</FieldTitle>
        <EditableTextArea
          value={event.description}
          onSubmit={(text) =>
            dispatch(
              putEvent({ axios, eventId, updates: { description: text } })
            )
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Lokalizacja</FieldTitle>
        <EditableTextInput
          value={event.location}
          onSubmit={(text) =>
            dispatch(putEvent({ axios, eventId, updates: { location: text } }))
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Widoczność ukryta</FieldTitle>
        <ToggleButton
          checked={event.hide}
          onChange={(checked) =>
            dispatch(putEvent({ axios, eventId, updates: { hide: checked } }))
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Wysyłanie zgłoszeń po rozpoczęciu</FieldTitle>
        <ToggleButton
          checked={event.ongoing_join}
          onChange={(checked) =>
            dispatch(
              putEvent({ axios, eventId, updates: { ongoing_join: checked } })
            )
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Zablokowane wysyłanie zgłoszeń</FieldTitle>
        <ToggleButton
          checked={event.lock_join}
          onChange={(checked) =>
            dispatch(
              putEvent({ axios, eventId, updates: { lock_join: checked } })
            )
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Maksymalna ilość osób</FieldTitle>
        <EditableNumberInput
          value={event.max_capacity}
          onSubmit={(value) =>
            dispatch(
              putEvent({ axios, eventId, updates: { max_capacity: value } })
            )
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Data rozpoczęcia zapisów</FieldTitle>
        <EditableDateInput
          value={new Date(event.entry_datetime)}
          onSubmit={(value) =>
            dispatch(
              putEvent({ axios, eventId, updates: { entry_datetime: value } })
            )
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Data rozpoczęcia wydarzenia</FieldTitle>
        <EditableDateInput
          value={new Date(event.start_datetime)}
          onSubmit={(value) =>
            dispatch(
              putEvent({ axios, eventId, updates: { start_datetime: value } })
            )
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Data zakończenia wydarzenia</FieldTitle>
        <EditableDateInput
          value={new Date(event.end_datetime)}
          onSubmit={(value) =>
            dispatch(
              putEvent({ axios, eventId, updates: { end_datetime: value } })
            )
          }
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Awatar wydarzenia</FieldTitle>
        <EditableImageInput
          value={event.avatar_url}
          onSubmit={async (file) => {
            try {
              const path = await uploadImage(axios, file);
              if (!path) return;
              dispatch(
                putEvent({ axios, eventId, updates: { avatar_url: path } })
              );
            } catch (error) {}
          }}
        />
      </EditableElement>

      <EditableElement>
        <FieldTitle>Baner wydarzenia</FieldTitle>
        <EditableImageInput
          value={event.banner_url}
          onSubmit={async (file) => {
            try {
              const path = await uploadImage(axios, file);
              if (!path) return;
              dispatch(
                putEvent({ axios, eventId, updates: { banner_url: path } })
              );
            } catch (error) {}
          }}
        />
      </EditableElement>
    </Element>
  );
};

export default EventSettings;
