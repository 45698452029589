import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  display: block;
  width: 100%;
  border-radius: 14px;
`;

interface EventBannerProps {
  src: string;
  alt: string;
}

const EventBanner: React.FC<EventBannerProps> = ({ src, alt }) => {
  return <Image className={'animate bottom-animate'} src={src} alt={alt} />;
};

export default EventBanner;
