import { FieldType, FormType } from '../../../../../types/FormTypes';
import React from 'react';
import Element from '../../../../EventView/Element';
import FieldTypeTitle from './FieldTypeTitle';
import EditableTextInput from '../editable/EditableTextInput';
import {
  createOption,
  deleteField,
  deleteOption,
  putField,
  putOption,
} from '../../../../../state/forms/formSlice';
import { AppDispatch } from '../../../../../state/store';
import { useDispatch } from 'react-redux';
import EditableTextArea from '../editable/EditableTextArea';
import EditableElement from '../editable/EditableElement';
import ToggleButton from '../ToogleButton';
import Button from '../../../../../atoms/Button';
import styled from 'styled-components';
import { FieldTitle } from '../../../../../molecules/Texts';
import { useAxios } from '../../../../../axiosConfig';

const LineWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
`;

interface FieldsSettingsProps {
  form: FormType;
}

const FieldsSettings: React.FC<FieldsSettingsProps> = ({ form }) => {
  const dispatch: AppDispatch = useDispatch();
  const axios = useAxios();

  return (
    <div>
      {form.fields.map((field) => (
        <Element>
          <FieldTypeTitle type={field.type} />
          <EditableElement>
            <FieldTitle>Pole wymagane</FieldTitle>
            <ToggleButton
              checked={field.required}
              onChange={(required) => {
                dispatch(
                  putField({
                    axios,
                    formId: form.id,
                    fieldId: field.id,
                    updates: { required },
                  })
                );
              }}
            />
          </EditableElement>
          <EditableElement>
            <FieldTitle>Tytuł pola</FieldTitle>
            <EditableTextInput
              value={field.title}
              onSubmit={(title) =>
                dispatch(
                  putField({
                    axios,
                    formId: form.id,
                    fieldId: field.id,
                    updates: { title },
                  })
                )
              }
            />
          </EditableElement>

          <EditableElement>
            <FieldTitle>Opis pola</FieldTitle>
            <EditableTextArea
              value={field.description}
              onSubmit={(description) =>
                dispatch(
                  putField({
                    axios,
                    formId: form.id,
                    fieldId: field.id,
                    updates: { description },
                  })
                )
              }
            />
          </EditableElement>

          {(field.type === FieldType.SINGLE_SELECT ||
            field.type === FieldType.MULTIPLE_SELECT) && (
            <EditableElement>
              <FieldTitle>Pola wyboru</FieldTitle>

              {field.options.map((option) => (
                <LineWrapper>
                  <EditableTextInput
                    value={option.text}
                    onSubmit={(text) =>
                      dispatch(
                        putOption({
                          axios,
                          formId: form.id,
                          fieldId: field.id,
                          optionId: option.id,
                          updates: { text },
                        })
                      )
                    }
                  />
                  <Button
                    color={'danger'}
                    onClick={() => {
                      dispatch(
                        deleteOption({
                          axios,
                          formId: form.id,
                          fieldId: field.id,
                          optionId: option.id,
                        })
                      );
                    }}
                  >
                    <img src={'/svg/delete.svg'} alt={'delete icon'} />
                  </Button>
                </LineWrapper>
              ))}
              <Button
                color={'success'}
                onClick={() =>
                  dispatch(
                    createOption({
                      axios,
                      formId: form.id,
                      fieldId: field.id,
                    })
                  )
                }
              >
                <img src={'/svg/add.svg'} alt={'add icon'} />
                <p>Nowa opcja </p>
              </Button>
            </EditableElement>
          )}
          <Button
            color={'danger'}
            onClick={() => {
              dispatch(
                deleteField({
                  axios,
                  formId: form.id,
                  fieldId: field.id,
                })
              );
            }}
          >
            <img src={'/svg/delete.svg'} alt={'delete icon'} />
            <p>Usuń pole</p>
          </Button>
        </Element>
      ))}
    </div>
  );
};

export default FieldsSettings;
