import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import SectionHeader from '../../../atoms/SectionHeader';
import PartnerItem from '../../../atoms/PartnerItem';
import ContentSection from '../../../atoms/sections/ContentSection';
import Section from '../../../atoms/sections/Section';
import Margin from '../../../atoms/Margin';
import partners from '../../../data/partners.json';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PartnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

const PartnerSection = () => {
  const theme = useContext(ThemeContext);
  if (!theme) return null;

  return (
    <Section bgColor={theme.colors.darkblue}>
      <ContentSection>
        <Wrapper>
          <Margin vertical={'100px'} />
          {partners.map((section, i) => {
            if (section.children.length === 0) return null;
            return (
                <>
                  <SectionHeader
                      description={section.description}
                      title={section.title}
                  />
                  <Margin vertical={'50px'} />
                  <PartnerWrapper>
                    {section.children.map((e) => (
                        <PartnerItem
                            key={e.id}
                            href={e.href}
                            src={e.src}
                            alt={e.alt}
                        />
                    ))}
                  </PartnerWrapper>
                  {partners.length !== i + 1 && <Margin vertical={'50px'} />}
                </>
            )
          })}
          <Margin vertical={'100px'} />
        </Wrapper>
      </ContentSection>
    </Section>
  );
};

export default PartnerSection;
