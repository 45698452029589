import React, { useEffect } from 'react';
import styled from 'styled-components';
import Logo from '../../../atoms/Logo';
import Button from '../../../atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '../Dropdown';
import AdminNavigateButton from './AdminNavigateButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  createEvent,
  fetchEvents,
  selectAllEvents,
  selectEventsStatus,
} from '../../../state/events/eventsSlice';
import { AppDispatch, RootState } from '../../../state/store';
import { useAxios } from '../../../axiosConfig';

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 750px) {
    display: none;
  }
`;

const NavigationWrapper = styled.div<{ $mobileVisible: boolean }>`
  overflow-y: scroll;
  overflow-x: auto;
  position: fixed;
  gap: 8px;
  z-index: 100;
  top: 0;
  left: 0;
  max-width: 300px;
  width: 80vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.quaternary};
  border-right: 2px solid ${({ theme }) => theme.colors.darkblue};
  display: ${({ $mobileVisible }) => ($mobileVisible ? 'grid' : 'none')};
  grid-template-rows: auto auto auto 1fr auto;
  @media screen and (min-width: 750px) {
    position: sticky;
    display: grid;
  }
`;

const LogoWrapper = styled.div`
  width: 125px;
  margin: 0 auto;
`;

const EventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

interface AdminNavigationProps {
  mobileVisible: boolean;
  closeMobileMenu: () => void;
}

const AdminNavigation: React.FC<AdminNavigationProps> = ({
  mobileVisible,
  closeMobileMenu,
}) => {
  const axios = useAxios();
  const events = useSelector(selectAllEvents);

  const { eventId: eventIdParam } = useParams();
  const eventId = eventIdParam ? parseInt(eventIdParam) : null;
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();
  const status = useSelector((state: RootState) => selectEventsStatus(state));

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchEvents());
    }
  }, []);

  return (
    <NavigationWrapper $mobileVisible={mobileVisible}>
      <div>
        <MobileWrapper>
          <Button onClick={closeMobileMenu}>Zamknij MENU</Button>
        </MobileWrapper>
      </div>

      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <div>
        <AdminNavigateButton
          to={`/admin/permissions`}
          label={'Uprawnienia'}
          src={'/svg/user.svg'}
        />
        <AdminNavigateButton
          to={`/admin/scanner`}
          label={'Skanowanie'}
          src={'/svg/qrcode.svg'}
        />
      </div>
      <EventsWrapper>
        {events.map((event) => {
          return (
            <Dropdown
              key={event.id}
              label={event.title}
              initialState={event.id === eventId}
              isFocused={event.id === eventId}
            >
              <AdminNavigateButton
                to={`/admin/events/${event.id}/settings`}
                label={'Ustawienia'}
                src={'/svg/edit.svg'}
              />
              <AdminNavigateButton
                to={`/admin/events/${event.id}/forms`}
                label={'Zgłoszenia'}
                src={'/svg/application.svg'}
              />
              <AdminNavigateButton
                to={`/admin/events/${event.id}/accepts`}
                label={'Zaakceptowane'}
                src={'/svg/checkbox.svg'}
              />
              <AdminNavigateButton
                to={`/admin/events/${event.id}/entries`}
                label={'Wpuszczone'}
                src={'/svg/group.svg'}
              />
              <AdminNavigateButton
                to={`/admin/events/${event.id}/search`}
                label={'Wyszukaj'}
                src={'/svg/search.svg'}
              />
            </Dropdown>
          );
        })}
      </EventsWrapper>
      <Button
        color={'success'}
        onClick={() => dispatch(createEvent({ axios }))}
      >
        Nowe Wydarzenie
      </Button>
      <Button color={'danger'} onClick={() => navigate('/logout')}>
        Wyloguj się
      </Button>
    </NavigationWrapper>
  );
};

export default AdminNavigation;
