import React from 'react';
import styled from 'styled-components';

import ModalWrapper from './ModalWrapper';
import ModalCard from './ModalCard';
import { useNavigate, useNavigation } from 'react-router-dom';

interface CheckEmailModalProps {
  onClick: () => void;
}

const CheckFieldsModal: React.FC<CheckEmailModalProps> = ({ onClick }) => {
  return (
    <ModalWrapper>
      <ModalCard
        title={'Wypełnij wszyskie wymagane pola!'}
        buttonLabel={'Powrót do formularza'}
        onClick={onClick}
      />
    </ModalWrapper>
  );
};

export default CheckFieldsModal;
