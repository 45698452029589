import React, { useEffect } from 'react';
import styled from 'styled-components';
import ContentSection from '../atoms/sections/ContentSection';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 15px;
  top: 0;
  left: 0;
`;

interface LockModalWrapperProps {
  children: React.ReactNode;
  lockedScroll?: boolean;
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  width: 100%;
`;

const ModalWrapper: React.FC<LockModalWrapperProps> = ({
  children,
  lockedScroll = true,
}) => {
  useEffect(() => {
    if (lockedScroll) {
      const closeModal = () => {
        document.body.style.overflow = 'auto';
      };
      document.body.style.overflow = 'hidden';
      return () => {
        closeModal();
      };
    }
  }, []);

  return (
    <Wrapper>
      <ContentSection>
        <ContentWrapper>{children}</ContentWrapper>
      </ContentSection>
    </Wrapper>
  );
};

export default ModalWrapper;
