import styled from 'styled-components';
import React from 'react';
import { FieldType } from '../../../../../types/FormTypes';
import { FieldTitle } from '../../../../../molecules/Texts';

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface FieldTypeTitleProps {
  type: FieldType;
}

const FieldTypeTitle: React.FC<FieldTypeTitleProps> = ({ type }) => {
  const label = (() => {
    switch (type) {
      case FieldType.TEXT:
        return 'Pole tekstowe';
      case FieldType.MULTIPLE_SELECT:
        return 'Pole wielokrotnego wyboru'; // Fixed typo here
      case FieldType.SINGLE_SELECT:
        return 'Pole jednokrotnego wyboru';
      default:
        return 'Inne';
    }
  })();

  const src = (() => {
    switch (type) {
      case FieldType.TEXT:
        return '/svg/draw.svg';
      case FieldType.MULTIPLE_SELECT:
        return '/svg/checkbox.svg';
      case FieldType.SINGLE_SELECT:
        return '/svg/radio.svg';
      default:
        return 'unknown';
    }
  })();

  return (
    <FieldTitle>
      <ContentWrapper>
        <img src={src} alt={label} />
        <p>{label}</p>
      </ContentWrapper>
    </FieldTitle>
  );
};

export default FieldTypeTitle;
