import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  margin: 0 0 30px 0;
  font-size: ${({ theme }) => theme.fonts.medium};
  font-weight: bold;
  padding: 18px 0;
  text-align: center;
  border-radius: 45px;
  color: ${({ theme }) => theme.colors.invertedText};
  background: transparent;
  background: ${({ theme }) => theme.colors.primary};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0 14px ${({ theme }) => theme.colors.primary};
  }

  &:active {
    transform: scale(0.985);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 14px ${({ theme }) => theme.colors.primary};
  }
`;

interface SubmitButtonProps {
  children: React.ReactNode;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ children }) => {
  return (
    <div className={'animate scale-animate'}>
      <Button type="submit">{children}</Button>
    </div>
  );
};

export default SubmitButton;
