import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import React from 'react';

const StyledNavLink = styled(NavLink)`
  display: block;
`;

const Img = styled.img`
  height: auto;
  width: 100%;
  border-radius: 8px;
`;

const Logo = () => {
  return (
    <StyledNavLink to={'/'}>
      <Img
        src={'/img/logo.png'}
        alt={'logo strategiczne spotkania w biznesie'}
      />
    </StyledNavLink>
  );
};

export default Logo;
