import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const StyledTableHeader = styled.thead`
  background-color: #f5f5f5;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #757575;
`;

export const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #fafafa;
  }

  &:hover {
    background-color: #b5b5b5;
  }
`;

export const StyledTableHeaderCell = styled.th`
  padding: 16px;
  border-bottom: 2px solid #e0e0e0;
`;

export const StyledTableCell = styled.td`
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  color: #424242;
`;
