import React, { useState } from 'react';
import styled from 'styled-components';

import Logo from '../../atoms/Logo';
import NavItem from './NavItem';
import Separator, { SeparatorSetType } from '../../atoms/Separator';

import WcagButton from '../WcagButton';
import MenuButton from '../MenuButton';

import MobileMenu from './MobileMenu';
// import FacebookButton from './media/FacebookButton';
// import LinkedinButton from './media/LinkedinButton';
// import InstagramButton from './media/InstagramButton';

const Wrapper = styled.div`
  height: 125px;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  align-items: center;
  margin: 18px 0;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-self: start;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-self: end;
  gap: 6px;
`;

const DesktopWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (width <= 900px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  @media screen and (width > 900px) {
    display: none;
  }
`;

const NavigationList = styled.ul`
  display: flex;
  list-style: none;
`;

const LogoWrapper = styled.div`
  width: 125px;
  height: 125px;
`;

const MiddleWrapper = styled.div`
  justify-self: center;
  display: flex;
`;

const NavBar = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen((prevState) => !prevState);

  return (
    <>
      <MobileMenu isOpen={isOpen} setOpen={setOpen} />
      <Wrapper>
        <LeftWrapper>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <DesktopWrapper>
            <Separator
              length={'40px'}
              set={SeparatorSetType.vertical}
              thickness={'2px'}
              marginX={'30px'}
            />
            <NavigationList>
              <li>
                <NavItem to={'/'}>Wydarzenia</NavItem>
              </li>
              <li>
                <NavItem to={'/about'}>O nas</NavItem>
              </li>
            </NavigationList>
          </DesktopWrapper>
        </LeftWrapper>
        <MiddleWrapper>
          <DesktopWrapper>
            {/*<LinkedinButton />*/}
            {/*<InstagramButton />*/}
            {/*<FacebookButton />*/}
          </DesktopWrapper>
        </MiddleWrapper>
        <RightWrapper>
          <WcagButton />
          <MobileWrapper>
            <MenuButton isOpen={isOpen} toggleOpen={toggleOpen} />
          </MobileWrapper>
        </RightWrapper>
      </Wrapper>
    </>
  );
};

export default NavBar;
