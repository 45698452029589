import styled from 'styled-components';
import React, { useState } from 'react';
import ModalCard from '../modals/ModalCard';
import ModalWrapper from '../modals/ModalWrapper';

export type ButtonColor = 'primary' | 'secondary' | 'success' | 'danger';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  confirm?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  color = 'primary',
  onClick,
  confirm,
  ...rest
}) => {
  const isConfirmButton =
    confirm !== undefined ? confirm : color === 'danger' || color === 'success';

  const [isConfirm, setConfirm] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isConfirmButton) {
      setConfirm(true);
    } else {
      onClick?.(event);
    }
  };

  const handleConfirmClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setConfirm(false);
    onClick?.(event);
  };

  const handleCancelClick = () => {
    setConfirm(false);
  };

  return (
    <>
      {isConfirm && (
        <ModalWrapper lockedScroll={false}>
          <ModalCard title={'Czy na pewno chcesz wykonać to działanie?'}>
            {children}
            <div>
              <Button
                color={'success'}
                confirm={false}
                onClick={handleConfirmClick}
              >
                Wykonaj
              </Button>
              <Button
                color={'danger'}
                confirm={false}
                onClick={handleCancelClick}
              >
                Powrót
              </Button>
            </div>
          </ModalCard>
        </ModalWrapper>
      )}
      <StyledButton $color={color} onClick={handleClick} {...rest}>
        {children}
      </StyledButton>
    </>
  );
};

const StyledButton = styled.button<
  { $color: ButtonColor } & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  background-color: ${(props) => props.theme.colors[props.$color]};
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.invertedText};
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  vertical-align: middle;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 2px;

  align-items: center;
  justify-content: center;

  gap: 5px;
  &:hover {
    filter: brightness(0.8);
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.9;
  }
`;

export default Button;
