import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';

interface PartnerItemProps {
  href: string;
  src: string;
  alt: string;
  key: string | number;
}

const Img = styled.img`
  border-radius: 14px;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const PartnerLink = styled(Link)`
  width: 125px;
  height: 125px;
  display: flex;
  justify-items: center;
  align-items: center;
`;

const PartnerItem: React.FC<PartnerItemProps> = ({ href, key, src, alt }) => {
  return (
    <PartnerLink
      className={'animate scale-animate'}
      key={key}
      to={href}
      target={'_blank'}
    >
      <Img src={src} alt={alt} />
    </PartnerLink>
  );
};

export default PartnerItem;
