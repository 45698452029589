import React from 'react';
import { Link } from './Link';

const ClickableText: React.FC<{ text: string }> = ({ text }) => {
  const renderTextWithLinks = (text: string) => {
    const regex = /https?:\/\/[^\s]+/g;
    const parts = text.split(regex);
    const links = text.match(regex);

    return parts.map((part, index) => {
      if (links && links[index]) {
        return (
          <React.Fragment key={index}>
            {part}
            <Link href={links[index]} target="_blank" rel="noopener noreferrer">
              {links[index]}
            </Link>
          </React.Fragment>
        );
      }
      return part; // Zwraca część tekstu
    });
  };

  return <>{renderTextWithLinks(text)}</>;
};

export default ClickableText;
