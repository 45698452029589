import styled from 'styled-components';

export const StyledSelect = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;

  &:focus {
    border-color: #3f51b5; /* Kolor po focusie */
    box-shadow: 0 0 5px rgba(63, 81, 181, 0.5); /* Efekt podświetlenia */
  }
`;

export const StyledOption = styled.option`
  padding: 10px;
`;
