import { useEffect, useState } from 'react';
import { useAxios } from '../../axiosConfig';
import { EventType } from '../../util/Api';

export const useEventId = (eventId: number | null) => {
  const [event, setEvent] = useState<EventType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const axios = useAxios();

  useEffect(() => {
    setIsLoading(true);

    if (!eventId) {
      setEvent(null);
      setIsLoading(false);
      return;
    }

    // fetch ticket
    (async function fetchTicket() {
      try {
        const response = await axios.get(`/events/${eventId}`);
        setEvent(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch ticket');
        setEvent(null);
      }
    })();

    return () => {
      setEvent(null);
    };
  }, [eventId]);
  return { event, isLoading };
};
