import styled from 'styled-components';

export const KeyText = styled.p`
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
`;

export const ValueText = styled.span`
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.text};
  font-weight: normal;
    padding: 4px;
`;

export const FieldTitle = styled.p`
  font-size: ${({ theme }) => theme.fonts.medium};
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FieldDescription = styled.p`
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.text};
`;
