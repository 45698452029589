import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    
  *, *::before, *::after {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
  }
  
  html {
      font-size: 62.5%;
  }
  
  body {
      line-height: 1.4;
      min-height: 100vh;
      padding: 0;
      margin: 0;
      
      font-size: ${({ theme }) => theme.fonts.normal};
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      
      color: ${({ theme }) => theme.colors.text};

      background: ${({ theme }) => theme.colors.secondary};
      background: linear-gradient(
              168deg,
              ${({ theme }) => theme.colors.secondary} 0%,
              ${({ theme }) => theme.colors.secondary} 33%,
              ${({ theme }) => theme.colors.darkblue} 100%
      );
  }
`;

export default GlobalStyle;
