import { Props } from './Editable';
import Input from '../../../../../atoms/Input';
import { BASE_URL } from '../../../../../util/Api';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 100px auto;
`;

const Img = styled.img`
  height: 100%;
  max-width: 100%;
  aspect-ratio: auto;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEX////09PQtDxrOAAAAE0lEQVQI12P4f4CBKMxg/4EYDAAFkR1NiYvv7QAAAABJRU5ErkJggg==');
  background-image: linear-gradient(45deg, $color 25%, transparent 25%),
    linear-gradient(-45deg, $color 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, $color 75%),
    linear-gradient(-45deg, transparent 75%, $color 75%);
  background-size: 20px 20px;
  background-position:
    0 0,
    0 10px,
    10px -10px,
    -10px 0px;
`;

const EditableImageInput: React.FC<Props<string, File>> = ({
  value,
  onSubmit,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onSubmit(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      // const dummyFile = new File([], value, { type: 'image/jpeg' });
      // const dataTransfer = new DataTransfer();
      // dataTransfer.items.add(dummyFile);
      // inputRef.current.files = dataTransfer.files;
      // inputRef.current.value = '';
    }
  }, [value]);

  return (
    <Wrapper>
      <Img src={BASE_URL + value} alt={'event'} />
      <Input
        ref={inputRef}
        onChange={handleChange}
        type={'file'}
        accept={'.jpg,.jpeg,.png'}
      />
    </Wrapper>
  );
};

export default EditableImageInput;
