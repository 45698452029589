import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import {
  NotificationType,
  useNotification,
} from './molecules/NotificationProvider';
import React from 'react';

interface ErrorResponse {
  error?: string;
  message?: string;
}

export const useAxios = () => {
  const { token, setToken } = useAuth();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  instance.interceptors.request.use((config) => {
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (response?.data?.message) {
        showNotification(response.data.message, NotificationType.INFO);
      }
      return response;
    },
    (error: AxiosError) => {
      if (!error.response) return;

      switch (error.response.status) {
        case 401: {
          setToken(null);
          navigate('/login');
          showNotification('Brak autoryzacji!', NotificationType.ERROR);
          break;
        }

        case 403: {
          showNotification('Brak uprawnień!', NotificationType.ERROR);
          break;
        }

        default: {
          const data = error.response.data as ErrorResponse;
          const message =
            'Błąd! (' +
            (data.error ||
              data.message ||
              'Serwer nie zaakceptował tego żądania') +
            ')';
          showNotification(message, NotificationType.ERROR);
          break;
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
