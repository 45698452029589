import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';

const StyledTextArea = styled.textarea`
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.quaternary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 10px;
  border-radius: 4px;
  line-height: 1.2;
  resize: none;
  overflow-y: auto;
  height: auto;
  min-height: 1rem;
  max-height: 300px;
  box-sizing: border-box;
  transition: all 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: 0 0 12px ${({ theme }) => theme.colors.primary};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.textLightGray};
    opacity: 0.6;
  }
`;

const TextArea: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  value,
  onChange,
  ...restProps
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.minHeight = 'auto';
      textAreaRef.current.style.minHeight = `min(${textAreaRef.current.scrollHeight}px, 300px)`;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    adjustHeight();
    if (onChange) onChange(event);
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  return (
    <StyledTextArea
      ref={textAreaRef}
      onChange={handleChange}
      value={value}
      {...restProps}
    />
  );
};

export default TextArea;
