const colors = {
  primary: 'white',
  secondary: 'black',
  tertiary: 'black',
  quaternary: 'black',
  text: 'white',
  invertedText: 'black',
  textLightGray: 'white',
  gray: 'white',
  darkblue: 'black',
  darkArea: 'black',
  eventArea: 'black',
  success: 'white',
  danger: 'white',
};

export default colors;
