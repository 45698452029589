import styled from 'styled-components';
import React from 'react';
import ContentWrapper from './sections/ContentSection';
import { HiddenLink, HiddenNavLink } from './Link';
import { SpaceRowWrapper } from '../molecules/Wrappers';
import media from '../data/media.json';
import Media from './MediaItem';

const StyledFooter = styled.footer`
  display: block;
  padding: 45px 0;
  background-color: ${({ theme }) => theme.colors.darkArea};
  width: 100%;
`;

const MediaWrapper = styled(SpaceRowWrapper)`
  display: flex;
  gap: 12px;
  margin-top: 15px;
`;

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 300;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 300px));
  gap: 30px;
  justify-items: start;
  justify-content: space-around;
`;

const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <ContentWrapper>
        <Wrapper>
          <ParagraphWrapper>
            <p>Koło Naukowe Analiz Strategicznych</p>
            <p>Uniwersytet Ekonomiczny w Krakowie</p>
            <p>ul. Rakowicka 27</p>
            <p>31-510 Kraków</p>
            <HiddenLink href="mailto:knas@uek.krakow.pl">
              <p>knas@uek.krakow.pl</p>
            </HiddenLink>
            <HiddenNavLink to={'/login'} rel={'nofollow'}>Panel Administratora</HiddenNavLink>
            <MediaWrapper>
              {media &&
                media.footer.map((media) => (
                  <Media
                    gray={true}
                    key={media.href}
                    size={'25px'}
                    href={media.href}
                    alt={media.alt}
                    src={media.src}
                  />
                ))}
            </MediaWrapper>
          </ParagraphWrapper>
          <ParagraphWrapper>
            <p>
              Aplikacja webowa stworzona przez:{' '}
              <HiddenLink
                href={'https://kamilszelagiewicz.pl'}
                target={'_blank'}
              >
                <p>Kamil Szelągiewicz</p>
              </HiddenLink>
            </p>
            <p>
              Kontakt mailowy:{' '}
              <HiddenLink href="mailto:kamil.szelagiewicz@gmail.com">
                <p>kamil.szelagiewicz@gmail.com</p>
              </HiddenLink>
            </p>
          </ParagraphWrapper>
        </Wrapper>
      </ContentWrapper>
    </StyledFooter>
  );
};

export default Footer;
