import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export enum SeparatorSetType {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

interface SeparatorProps {
  length: string;
  set: SeparatorSetType;
  thickness: string;
  marginX?: string;
  marginY?: string;
}

const Separator = styled.div<SeparatorProps>`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray};
  padding: 0;
  background-color: ${({ theme }) => theme.colors.gray};
  width: ${({ set, length, thickness }) =>
    set === 'horizontal' ? length : thickness};
  height: ${({ set, length, thickness }) =>
    set === 'vertical' ? length : thickness};
  margin: ${({ marginX = 0, marginY = 0 }) => `${marginY} ${marginX};`};
`;

export default Separator;
