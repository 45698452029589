import React from 'react';
import Button from '../../atoms/Button';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

interface AcceptRejectProps {
  accept: () => void;
  reject: () => void;
  acceptLabel?: string;
  rejectLabel?: string;
}

const AcceptReject: React.FC<AcceptRejectProps> = ({
  accept,
  reject,
  acceptLabel = 'Akceptuj',
  rejectLabel = 'Odrzuć',
}) => {
  return (
    <ButtonWrapper>
      <Button color={'danger'} onClick={reject}>
        <img src={'/svg/cancel.svg'} alt={'ikona odrzucenia'} />
        <p>{rejectLabel}</p>
      </Button>
      <Button color={'success'} onClick={accept}>
        <img src={'/svg/checkbox.svg'} alt={'ikona akceptacji'} />
        <p>{acceptLabel}</p>
      </Button>
    </ButtonWrapper>
  );
};

export default AcceptReject;
