import { Props, Wrapper } from './Editable';
import { useEffect, useState } from 'react';
import Input from '../../../../../atoms/Input';
import EditableMenu from './EditableMenu';

const EditableTextInput: React.FC<Props<string, string>> = ({
  value,
  onSubmit,
}) => {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>(value);

  useEffect(() => {
    setPrompt(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = () => {
    setEditMode(false);
    setPrompt(value);
    onSubmit(prompt);
  };

  const handleRollback = () => {
    setEditMode(false);
    setPrompt(value);
  };

  return (
    <Wrapper>
      <Input
        onChange={handleChange}
        value={prompt}
        disabled={!isEditMode}
        type={'text'}
      />
      <EditableMenu
        isEditing={isEditMode}
        onEdit={() => setEditMode(true)}
        onCancel={handleRollback}
        onSubmit={handleSubmit}
      />
    </Wrapper>
  );
};

export default EditableTextInput;
