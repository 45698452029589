import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteEvent,
  selectEventById,
} from '../../../../state/events/eventsSlice';
import { Navigate, useParams } from 'react-router-dom';
import Button from '../../../../atoms/Button';
import { AppDispatch } from '../../../../state/store';
import {
  fetchForm,
  selectForm,
  selectFormStatus,
} from '../../../../state/forms/formSlice';
import EventSettings from './EventSettings';
import CreateFields from './CreateFields';
import FieldsSettings from './fields/FieldsSettings';
import { useAxios } from '../../../../axiosConfig';
import ExcelButton from './ExcelButton';
import { FieldTitle } from '../../../../molecules/Texts';

const SettingsView = () => {
  const { eventId: eventIdParam } = useParams();
  const eventId = eventIdParam ? parseInt(eventIdParam) : NaN;
  const event = useSelector(selectEventById(eventId));
  const dispatch: AppDispatch = useDispatch();
  const form = useSelector(selectForm);
  const formStatus = useSelector(selectFormStatus);
  const axios = useAxios();

  useEffect(() => {
    dispatch(fetchForm({ axios, formId: eventId }));
  }, [eventId, dispatch]);

  if (formStatus !== 'succeeded' || !form) return <p>Loading...</p>;

  if (!eventIdParam || isNaN(eventId) || !event) {
    return <Navigate to="/admin" />;
  }

  return (
    <div>
      <FieldTitle>Panel Zarządzania Ustawieniami Wydarzenia</FieldTitle>
      <EventSettings event={event} />
      <CreateFields form={form} />
      <FieldsSettings form={form} />
      <ExcelButton eventId={event.id} title={event.title} />
      <Button
        color={'danger'}
        onClick={() => dispatch(deleteEvent({ axios, eventId }))}
      >
        <img src={'/svg/delete.svg'} alt={'usuń wydarzenie'} />
        <p>Usuń wydarzenie</p>
      </Button>
    </div>
  );
};

export default SettingsView;
