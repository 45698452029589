import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`;

export const HiddenLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: underline;
`;

export const HiddenNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: underline;
`;
