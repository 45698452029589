const colors = {
  primary: '#05ACEF', //'#BB89E0',
  secondary: '#011332', //'#0F0D2A',
  tertiary: '#083266', //'#1f1a5d',
  quaternary: '#090B16',
  text: '#fff',
  invertedText: '#fff',
  textLightGray: '#dadada',
  gray: '#6c757d',
  darkblue: '#0E101E',
  darkArea: '#121424',
  eventArea: '#090B16',
  success: '#28A745',
  danger: '#DC3545',
};

export default colors;
