import React, { useEffect, useState } from 'react';
import { BASE_URL, EventType } from '../../../util/Api';
import {
  AnyFieldType,
  FieldType,
  FormType,
  ResponseSelectableType,
  ResponseTextType,
  ResponseType,
} from '../../../types/FormTypes';
import TextField from './TextField';
import SelectField from './SelectField';
import SubmitButton from './SubmitButton';
import styled from 'styled-components';
import axios from 'axios';
import BonusField from './BonusField';
import Loading from '../../../modals/Loading';
import CheckEmailModal from '../../../modals/CheckEmailModal';
import CheckFieldsModal from '../../../modals/CheckFieldsModal';
import { RequestType } from '../../../types/Request';
import { useAxios } from '../../../axiosConfig';

const checkResponsesValid = (
  fields: AnyFieldType[],
  responses: ResponseType[]
): boolean => {
  for (const field of fields) {
    if (!field.required) continue;
    const response = responses.find((e) => e.field_schema_id === field.id);
    if (!response) return false;
    switch (response.type) {
      case FieldType.TEXT:
        if (!response.text) return false;
        break;
      case FieldType.SINGLE_SELECT:
        if (!response.options.length) return false;
        break;
      case FieldType.MULTIPLE_SELECT:
        if (!response.options.length) return false;
        break;
    }
  }
  return true;
};

const nonfunctionalFunction = () => {};

const responseSchemaFromForm = (
  form: FormType | RequestType
): ResponseType[] => {
  return form.fields.map((field) => {
    switch (field.type) {
      case FieldType.TEXT:
        return {
          field_schema_id: field.id,
          text:
            'response' in field && field.response ? field.response.text : '',
          type: FieldType.TEXT,
        };
      case FieldType.SINGLE_SELECT:
        return {
          field_schema_id: field.id,
          options:
            'response' in field && field.response ? field.response.options : [],
          type: FieldType.SINGLE_SELECT,
        };
      case FieldType.MULTIPLE_SELECT:
        return {
          field_schema_id: field.id,
          options:
            'response' in field && field.response ? field.response.options : [],
          type: FieldType.MULTIPLE_SELECT,
        };
      default:
        throw new Error();
    }
  });
};

interface EventFormProps {
  event: EventType;
  form: FormType | RequestType;
  editable?: boolean;
  disabled?: boolean;
}

const Form = styled.form``;

const FieldSet = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  &:disabled {
    filter: grayscale(100%) brightness(70%);
    pointer-events: none;
    opacity: 0.6;
  }
`;

const EventForm: React.FC<EventFormProps> = ({
  event,
  form,
  editable = true,
  disabled = false,
}) => {
  const [responses, setResponses] = useState<ResponseType[]>(
    responseSchemaFromForm(form)
  );
  const initialEmail = 'email' in form ? form.email : '';
  const [email, setEmail] = useState<string>(initialEmail);
  const [postLock, setPostLock] = useState(false);
  const [isSend, setSend] = useState(false);
  const [isCheckFieldsModal, setCheckFieldsModal] = useState(false);
  const axios = useAxios();

  useEffect(() => {
    setResponses(responseSchemaFromForm(form));
    const initialEmail = 'email' in form ? form.email : '';
    setEmail(initialEmail);
  }, [form]);

  const updateTextField = (fieldSchemaId: number, text: string) => {
    setResponses((prevState) => {
      return prevState.map((response) => {
        if (response.field_schema_id === fieldSchemaId) {
          return {
            ...response,
            text: text,
          };
        }
        return response;
      });
    });
  };
  const updateSelectableField = (fieldSchemaId: number, options: number[]) => {
    setResponses((prevState) => {
      return prevState.map((response) => {
        if (response.field_schema_id === fieldSchemaId) {
          return {
            ...response,
            options: options,
          };
        }
        return response;
      });
    });
  };

  const getTextResponse = (fieldSchemaId: number): ResponseTextType => {
    return responses.find(
      (e) => e.field_schema_id === fieldSchemaId
    ) as ResponseTextType;
  };

  const getSelectableResponse = (
    fieldSchemaId: number
  ): ResponseSelectableType => {
    return responses.find(
      (e) => e.field_schema_id === fieldSchemaId
    ) as ResponseSelectableType;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (postLock) return;
    if (isSend) return;

    const isValid = checkResponsesValid(form.fields, responses);
    if (!isValid) {
      setCheckFieldsModal(true);
      return;
    }

    const minimalizedResponse = responses
      .filter((response) => {
        if (response.type === FieldType.TEXT && response.text === '')
          return false;
        if (
          response.type === FieldType.SINGLE_SELECT &&
          response.options.length === 0
        )
          return false;
        return !(
          response.type === FieldType.MULTIPLE_SELECT &&
          response.options.length === 0
        );
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ type, ...rest }) => ({ ...rest }));

    setPostLock(true);
    try {
      await axios.post(`/events/${event.id}/requests`, {
        email: email,
        fields: minimalizedResponse,
      });
      setSend(true);
    } catch (error) {
      console.error('Failed to send request', error);
      return;
    } finally {
      setPostLock(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FieldSet disabled={disabled}>
          <BonusField
            type={'email'}
            placeholder={'Wpisz tutaj swój adres e-mail...'}
            value={email}
            onChange={editable ? setEmail : nonfunctionalFunction}
            title={'Adres e-mail'}
            description={
              'Podaj adres e-mail, na który wyślemy potwierdzenie zgłoszenia oraz bilet, gdy Twoje zgłoszenie zostanie zaakceptowane.'
            }
            required={true}
          />
          {form.fields.map((field) => {
            if (field.type === FieldType.TEXT) {
              return (
                <TextField
                  key={field.id}
                  field={field}
                  value={getTextResponse(field.id).text}
                  onChange={editable ? updateTextField : nonfunctionalFunction}
                />
              );
            }
            if (
              field.type === FieldType.SINGLE_SELECT ||
              field.type === FieldType.MULTIPLE_SELECT
            ) {
              return (
                <SelectField
                  key={field.id}
                  field={field}
                  values={getSelectableResponse(field.id).options}
                  onChange={
                    editable ? updateSelectableField : nonfunctionalFunction
                  }
                />
              );
            }
            return null;
          })}
          {editable && <SubmitButton>Prześlij zgłoszenie</SubmitButton>}
        </FieldSet>
      </Form>
      {isSend && <CheckEmailModal />}
      {postLock && <Loading />}
      {isCheckFieldsModal && (
          <CheckFieldsModal onClick={() => setCheckFieldsModal(false)} />
      )}
    </>
  );
};

export default EventForm;
