import styled from 'styled-components';
import React from 'react';

interface SmallResponsiveIconButtonProps {
  bgColor: string;
  color: string;
  src: string;
  alt: string;
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const Button = styled.button<
  Pick<SmallResponsiveIconButtonProps, 'bgColor' | 'color' | 'text'>
>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  height: 45px;
  min-width: 45px;
  padding: 8px;
  gap: ${({ text }) => (text ? '5px' : '0')};
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.03);
    opacity: 0.8;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.9;
  }
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;

  filter: ${({ theme }) =>
    theme.colors.text !== theme.colors.invertedText ? 'invert()' : 'none'};
`;

const Text = styled.p`
  @media screen and (max-width: 400px) {
    display: none;
  }
`;

const SmallResponsiveIconButton: React.FC<SmallResponsiveIconButtonProps> = ({
  bgColor,
  color,
  src,
  alt,
  text,
  onClick,
  className = '', // Default value
}) => (
  <Button
    bgColor={bgColor}
    color={color}
    onClick={onClick}
    className={className}
    text={text}
  >
    <Img src={src} alt={alt} />
    {text && <Text>{text}</Text>} {/* Render text conditionally */}
  </Button>
);

export default SmallResponsiveIconButton;
