import React, { useEffect, useState } from 'react';
import { UserType } from '../../../../types/User';
import { useAxios } from '../../../../axiosConfig';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeader,
  StyledTableHeaderCell,
  StyledTableRow,
} from '../../../../atoms/Table';
import Button from '../../../../atoms/Button';
import AccountEditor from './AccountEditor';
import styled from 'styled-components';
import Margin from '../../../../atoms/Margin';
import { FieldTitle } from '../../../../molecules/Texts';

const BoolDot = styled.p<{ $status: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${({ theme, $status }) =>
    $status ? theme.colors.success : theme.colors.danger};
`;

const PermissionView = () => {
  const [users, setUsers] = useState<UserType[]>([]);
  const axios = useAxios();

  const [updateKey, setUpdateKey] = useState(1);
  const nextUpdate = () => {
    setUpdateKey((prev) => prev + 1);
  };

  const [editUser, setEditUser] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    (async function fetchUsers() {
      try {
        const response = await axios.get<UserType[]>('/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users', error);
        setUsers([]);
      }
    })();
  }, [updateKey]);

  const handleAccountEditor = (id: number | null) => {
    setEditUser(id);
    setIsEditing(true);
  };

  const handleCloseEditor = () => {
    setEditUser(null);
    setIsEditing(false);
    nextUpdate();
  };

  return (
    <div>
      <FieldTitle>Panel Zarządzania Uprawnieniami i Dostępem</FieldTitle>
      {isEditing && (
        <AccountEditor id={editUser} updateClose={handleCloseEditor} />
      )}
      <Button color={'success'} onClick={() => handleAccountEditor(null)}>
        Nowe Konto
      </Button>

      <Margin vertical={'10px'} />

      <StyledTable>
        <StyledTableHeader>
          <StyledTableRow>
            <StyledTableHeaderCell>Id</StyledTableHeaderCell>
            <StyledTableHeaderCell>Nazwa</StyledTableHeaderCell>
            <StyledTableHeaderCell>Rola</StyledTableHeaderCell>
            <StyledTableHeaderCell>Login</StyledTableHeaderCell>
            <StyledTableHeaderCell>Włączony</StyledTableHeaderCell>
            <StyledTableHeaderCell>Operacja</StyledTableHeaderCell>
          </StyledTableRow>
        </StyledTableHeader>
        {users.map((user) => (
          <StyledTableRow key={user.id}>
            <StyledTableCell>{user.id}</StyledTableCell>
            <StyledTableCell>{user.name}</StyledTableCell>
            <StyledTableCell>{user.role.name}</StyledTableCell>
            <StyledTableCell>{user.username}</StyledTableCell>
            <StyledTableCell>
              <BoolDot $status={user.enable} />
              <p>{user.enable ? 'aktywne' : 'wyłączone'}</p>
            </StyledTableCell>
            <StyledTableCell>
              <Button onClick={() => handleAccountEditor(user.id)}>
                Edytuj
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </StyledTable>
    </div>
  );
};

export default PermissionView;
