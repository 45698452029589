import React from 'react';
import styled from 'styled-components';

import ModalWrapper from './ModalWrapper';
import ModalCard from './ModalCard';
import { useNavigate, useNavigation } from 'react-router-dom';

const CheckEmailModal = () => {
  const navigate = useNavigate();

  return (
    <ModalWrapper>
      <ModalCard
        title={'Dziękujemy za przesłanie zgłoszenia!'}
        buttonLabel={'Strona Główna'}
        onClick={() => navigate('/')}
      >
        <>
          <img src={'/svg/mail.svg'} alt="mail" width={'45px'} />
          <p>
            Twoje zgłoszenie zostało pomyślnie zarejestrowane. Prosimy o
            sprawdzenie swojej skrzynki e-mail, aby uzyskać szczegółowe
            informacje.
          </p>
          <p>
            Obecnie Twoje zgłoszenie oczekuje na weryfikację przez organizatora.
            O decyzji zostaniesz poinformowany drogą e-mailową. W przypadku
            pozytywnej decyzji otrzymasz bilet na podany adres e-mail.
          </p>
          <p>
            Prosimy również o sprawdzenie folderu spam, aby upewnić się, że
            nasze wiadomości nie zostały tam przypadkowo skierowane.
          </p>
          <p>Dziękujemy za Twoje zainteresowanie!</p>
        </>
      </ModalCard>
    </ModalWrapper>
  );
};

export default CheckEmailModal;
