import styled from 'styled-components';
import React from 'react';

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    width: 25px;
    height: 25px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50px;
  }
`;

const Checkbox = styled.input`
  display: none;
  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:checked + ${Slider}:before {
    transform: translateX(25px);
  }
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
  margin: 0 10px;
`;

interface ToggleButtonProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ checked, onChange }) => {
  return (
    <Switch>
      <Checkbox
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <Slider></Slider>
    </Switch>
  );
};

export default ToggleButton;
