import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

const Wrapper = styled.nav`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  @media screen and (min-width: 750px) {
    display: none;
  }
`;

interface MobileAdminNavigationProps {
  toggleMobileMenu: () => void;
}

const MobileAdminNavigation: React.FC<MobileAdminNavigationProps> = ({
  toggleMobileMenu,
}) => {
  return (
    <Wrapper>
      <Button onClick={toggleMobileMenu}>MENU</Button>
    </Wrapper>
  );
};

export default MobileAdminNavigation;
