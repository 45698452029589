import SmallResponsiveIconButton from '../atoms/SmallResponsiveIconButton';
import React, { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ColorOptions, FontOptions, ThemeContext } from '../theme/ThemeContext';

const DropdownMenu = styled.ul<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin-top: 10px;
  width: 230px;
  z-index: 100;
  right: 0;
`;

const DropdownItem = styled.li``;

const Wrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  padding: 20px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  background: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #777;
  }
`;

const WcagButton = () => {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error('ThemeContext not found');
  }

  const [isOpen, setOpen] = useState<boolean>(false);
  const { setColorOption, setFontOption } = themeContext;

  const toggleDropdown = () => {
    setOpen(!isOpen);
  };

  const theme = useTheme();

  return (
    <Wrapper>
      <SmallResponsiveIconButton
        className={'wcag accessible'}
        onClick={toggleDropdown}
        bgColor={theme.colors.primary}
        color={theme.colors.invertedText}
        src={'/svg/accessible.svg'}
        alt={'wcag button'}
        text={'WCAG'}
      />
      <DropdownMenu $isOpen={isOpen}>
        <DropdownItem>
          <Button onClick={() => setFontOption(FontOptions.DEFAULT)}>A+</Button>
        </DropdownItem>
        <DropdownItem>
          <Button onClick={() => setFontOption(FontOptions.MEDIUM)}>A++</Button>
        </DropdownItem>
        <DropdownItem>
          <Button onClick={() => setFontOption(FontOptions.LARGE)}>A+++</Button>
        </DropdownItem>
        <DropdownItem>
          <Button onClick={() => setColorOption(ColorOptions.DEFAULT)}>
            Kolory klasyczne
          </Button>
        </DropdownItem>
        <DropdownItem>
          <Button onClick={() => setColorOption(ColorOptions.BLACK_WHITE)}>
            Tryb czarno-biały
          </Button>
        </DropdownItem>
        <DropdownItem>
          <Button onClick={() => setColorOption(ColorOptions.BLACK_YELLOW)}>
            Tryb czarno-zółty
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Wrapper>
  );
};

export default WcagButton;
