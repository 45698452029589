import styled from 'styled-components';
import React from 'react';

interface ElementProps {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  margin: 30px 0;
  background-color: ${({ theme }) => theme.colors.quaternary};
  padding: 30px;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
`;

const Element: React.FC<ElementProps> = ({ children }) => {
  return <Wrapper className={'animate bottom-animate'}>{children}</Wrapper>;
};

export default Element;
