import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ModalWrapper from './ModalWrapper';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinningImage = styled.img`
  width: 50px;
  animation: ${spin} 1s ease infinite; // Animacja kręcenia
`;

const Message = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fonts.normal};
`;

const Loading = () => {
  return (
    <ModalWrapper>
      <SpinningImage src={'/svg/sync.svg'} alt={'sync icons'} />
      <Message>Synchronizacja z serwerem</Message>
    </ModalWrapper>
  );
};

export default Loading;
