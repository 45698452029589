import React from 'react';
import FieldTitleDescription from './FieldTitleDescription';
import Element from '../Element';
import {
  FieldType,
  MultipleSelectFieldType,
  SingleSelectFieldType,
} from '../../../types/FormTypes';
import styled from 'styled-components';

interface SelectFieldProps {
  field: SingleSelectFieldType | MultipleSelectFieldType;
  values: number[];
  onChange: (field_schema_id: number, options: number[]) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  margin-right: 0.8rem;
  cursor: pointer;
  width: 22px;
  height: 22px;
  border: 2px solid ${({ theme }) => theme.colors.gray};
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.3s ease;
  flex-shrink: 0;
  &:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.gray};
  }
  &:checked:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 6px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  &[type='radio'] {
    border-radius: 50%;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: 0 0 12px ${({ theme }) => theme.colors.primary};
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fonts.normal};
`;

const SelectField: React.FC<SelectFieldProps> = ({
  field,
  values,
  onChange,
}) => {
  return (
    <Element>
      <FieldTitleDescription
        title={field.title}
        description={field.description}
        required={field.required}
      />
      <Wrapper>
        {field.options.map((option) => (
          <OptionWrapper key={option.id}>
            <Input
              id={'option' + option.id} // Ensure the id is unique for each input
              name={'option' + field.id} // Group checkboxes/radios by the same name
              type={
                field.type === FieldType.MULTIPLE_SELECT ? 'checkbox' : 'radio'
              }
              value={option.id}
              checked={values.includes(option.id)}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                const input: HTMLInputElement = e.target as HTMLInputElement;
                const parsedValue = parseInt(input.value);
                if (!input.checked && !values.includes(parsedValue)) return;
                onChange(field.id, []);
              }}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (field.type === FieldType.MULTIPLE_SELECT) {
                  if (e.target.checked) {
                    onChange(field.id, [...values, parsedValue]);
                  } else {
                    onChange(
                      field.id,
                      values.filter((index) => index !== parsedValue)
                    );
                  }
                } else {
                  onChange(field.id, [parsedValue]);
                }
              }}
            />
            <Label htmlFor={'option' + option.id}>{option.text}</Label>
          </OptionWrapper>
        ))}
      </Wrapper>
    </Element>
  );
};

export default SelectField;
