import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export interface Props<T, U> {
  value: T;
  onSubmit: (value: U) => void;
}
