import styled from 'styled-components';

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 15px;
`;

export default ContentWrapper;
