export enum FieldType {
  TEXT = 'TEXT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
}

export interface Field {
  id: number;
  title: string;
  description: string;
  required: boolean;
  type: FieldType;
}

export interface TextFieldType extends Field {
  type: FieldType.TEXT;
}

export interface OptionType {
  id: number;
  text: string;
}

export interface SingleSelectFieldType extends Field {
  type: FieldType.SINGLE_SELECT;
  options: OptionType[];
}

export interface MultipleSelectFieldType extends Field {
  type: FieldType.MULTIPLE_SELECT;
  options: OptionType[];
}

export type AnyFieldType =
  | TextFieldType
  | SingleSelectFieldType
  | MultipleSelectFieldType;

export interface FormType {
  id: number;
  fields: AnyFieldType[];
  canJoin: ICanJoin
}

export interface ICanJoin {
  status: boolean,
  message: string;
}

interface BaseResponseType {
  field_schema_id: number;
  type: FieldType;
}

export interface ResponseTextType extends BaseResponseType {
  text: string;
  type: FieldType.TEXT;
}

export interface ResponseSelectableType extends BaseResponseType {
  options: number[];
  type: FieldType.SINGLE_SELECT | FieldType.MULTIPLE_SELECT;
}

export interface ResponseSingleSelectType extends ResponseSelectableType {
  type: FieldType.SINGLE_SELECT;
}

export interface ResponseMultipleSelectType extends ResponseSelectableType {
  type: FieldType.MULTIPLE_SELECT;
}

export type ResponseType =
  | ResponseTextType
  | ResponseSingleSelectType
  | ResponseMultipleSelectType;

export interface PutFieldType {
  title?: string;
  description?: string;
  required?: boolean;
}

export interface PutOptionType {
  text?: string;
}
