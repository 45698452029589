import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainView from './views/MainView/MainView';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import EventView from './views/EventView/EventView';
import ThemeContextProvider from './theme/ThemeContext';
import GlobalStyle from './theme/GlobalStyle';
import Animate from './js/Animate';
import { AuthProvider } from './AuthContext';
import LoginView from './views/LoginView';
import LogoutView from './views/LogoutView';
import AdminView from './views/AdminView/AdminView';
import Footer from './atoms/Footer';
import { Provider } from 'react-redux';
import { store } from './state/store';
import AdminNavigation from './views/AdminView/navigation/AdminNavigation';
import RequestPaginationView from './views/AdminView/pages/requests/RequestPaginationView';
import AdminSecure from './views/AdminView/AdminSecure';
import SettingsView from './views/AdminView/pages/settings/SettingsView';
import ScannerView from './views/AdminView/pages/scanner/ScannerView';
import NotificationProvider from './molecules/NotificationProvider';
import styled from 'styled-components';
import MobileAdminNavigation from './atoms/sections/MobileAdminNavigation';
import AboutView from './views/AboutView/AboutView';
import SearchView from './views/AdminView/pages/search/SearchView';
import { RequestStatusType } from './types/Request';
import ContentSection from './atoms/sections/ContentSection';
import PermissionsView from './views/AdminView/pages/permissions/PermissionsView';
import { MarginWrapper } from './molecules/Wrappers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`;

const UserOutletRouter = () => {
  return (
    <>
      <GlobalStyle />
      <Outlet />
      <Animate />
      <Footer />
    </>
  );
};

const AdminOutletRouter = () => {
  const [mobileVisible, setMobileVisible] = useState<boolean>(false);

  const toggleMobileMenu = () => {
    setMobileVisible((prevState) => !prevState);
  };

  return (
    <>
      <GlobalStyle />
      <AdminSecure />
      <Animate />
      <Provider store={store}>
        <Wrapper>
          <AdminNavigation
            mobileVisible={mobileVisible}
            closeMobileMenu={() => setMobileVisible(false)}
          />
          <div>
            <MobileAdminNavigation toggleMobileMenu={toggleMobileMenu} />
            <ContentSection>
              <MarginWrapper>
                <Outlet />
              </MarginWrapper>
            </ContentSection>
            <Footer />
          </div>
        </Wrapper>
      </Provider>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <UserOutletRouter />,
    children: [
      { index: true, path: '/', element: <MainView /> },
      { path: '/about', element: <AboutView /> },
      { path: '/events/:eventId', element: <EventView /> },
      { path: '/login', element: <LoginView /> },
      { path: '/logout', element: <LogoutView /> },
    ],
  },
  {
    path: '/admin',
    element: <AdminOutletRouter />,
    children: [
      {
        index: true,
        path: '/admin',
        element: <AdminView />,
      },
      {
        path: '/admin/events/:eventId/settings',
        element: <SettingsView />,
      },
      {
        path: '/admin/events/:eventId/forms',
        element: (
          <RequestPaginationView
            requestStatusType={RequestStatusType.PENDING}
          />
        ),
      },
      {
        path: '/admin/events/:eventId/accepts',
        element: (
          <RequestPaginationView
            requestStatusType={RequestStatusType.ACCEPTED}
          />
        ),
      },
      {
        path: '/admin/events/:eventId/entries',
        element: (
          <RequestPaginationView requestStatusType={RequestStatusType.ENTER} />
        ),
      },
      {
        path: '/admin/events/:eventId/search',
        element: <SearchView />,
      },
      {
        path: '/admin/scanner',
        element: <ScannerView />,
      },
      {
        path: '/admin/permissions',
        element: <PermissionsView />,
      },
    ],
  },
]);

root.render(
  // <React.StrictMode>
  <ThemeContextProvider>
    <AuthProvider>
      <>
        <NotificationProvider>
          <RouterProvider router={router} />
        </NotificationProvider>
      </>
    </AuthProvider>
  </ThemeContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
