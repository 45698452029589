import React, { useRef, useEffect } from 'react';
import styles from './TextField.module.scss';

import { TextFieldType } from '../../../types/FormTypes';
import styled from 'styled-components';
import Element from '../Element';
import FieldTitleDescription from './FieldTitleDescription';
import TextArea from './TextArea';

interface TextFieldProps {
  field: TextFieldType;
  value: string;
  onChange: (field_schema_id: number, text: string) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

// const TextArea = styled.textarea`
//   font-size: ${({ theme }) => theme.fonts.normal};
//   color: ${({ theme }) => theme.colors.text};
//   background-color: ${({ theme }) => theme.colors.quaternary};
//   border: 1px solid ${({ theme }) => theme.colors.primary};
//
//   padding: 10px;
//
//   border-radius: 4px;
//   line-height: 1.2;
//   resize: none; /* Blokuje możliwość ręcznego skalowania */
//   overflow-y: hidden; /* Ukrywa pionowy pasek przewijania */
//   height: auto;
//   min-height: 1rem; /* Wysokość odpowiadająca jednej linijce tekstu */
//   max-height: 300px; /* Opcjonalnie: Maksymalna wysokość pola */
//   box-sizing: border-box; /* Zapewnia prawidłowe rozmiary pola */
//
//   transition: all 0.3s ease;
//
//   &:focus {
//     border-color: ${({ theme }) => theme.colors.primary};
//     outline: none;
//     box-shadow: 0 0 12px ${({ theme }) => theme.colors.primary};
//   }
//
//   &::placeholder {
//     color: ${({ theme }) => theme.colors.textLightGray};
//     opacity: 0.6;
//   }
// `;

const TextField: React.FC<TextFieldProps> = ({ field, value, onChange }) => {
  // const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  // useEffect(() => {
  //   if (textAreaRef.current) {
  //     textAreaRef.current.style.height = 'auto';
  //     textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  //   }
  // }, [value]);

  return (
    <Element>
      <FieldTitleDescription
        title={field.title}
        description={field.description}
        required={field.required}
      />
      <Wrapper>
        <TextArea
          id={'' + field.id}
          // ref={textAreaRef}
          value={value}
          onChange={(e) => onChange(field.id, e.target.value)}
          placeholder={'Wpisz tutaj swoją odpowiedź...'}
        />
      </Wrapper>
    </Element>
  );
};

export default TextField;
