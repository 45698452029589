import styled from 'styled-components';
import Button, { ButtonColor } from '../atoms/Button';
import React from 'react';

const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  padding: 30px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.text};
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

interface ModalCardProps {
  title: string;
  onClick?: () => void;
  buttonColor?: ButtonColor;
  buttonLabel?: string;
  children?: React.ReactNode;
}

const ModalCard: React.FC<ModalCardProps> = ({
  title,
  children,
  onClick,
  buttonColor = 'primary',
  buttonLabel = 'Zamknij',
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
      {onClick && (
        <Button color={buttonColor} onClick={onClick}>
          {buttonLabel}
        </Button>
      )}
    </Wrapper>
  );
};

export default ModalCard;
