import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const LogoutView = () => {
  const { token, setToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setToken(null);
    navigate('/');
  }, [token, navigate]);

  return null;
};

export default LogoutView;
