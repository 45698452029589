import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

interface TitleProps {
  children: React.ReactNode;
}

const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fonts.huge};
  font-weight: bold;
  width: 100%;
  text-align: center;
  @media (max-width: 400px) {
    font-size: ${({ theme }) => theme.fonts.big};
  }
`;

const Title: React.FC<TitleProps> = ({ children }) => {
  return <Paragraph className={'animate left-animate'}>{children}</Paragraph>;
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
