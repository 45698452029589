import styled, { useTheme } from 'styled-components';
import React from 'react';
import { BASE_URL, EventType } from '../../../../util/Api';
import { Link } from 'react-router-dom';
import { format } from '../../../../js/DatetimeFormat';
import Button from '../../../../atoms/Button';

interface EventCardProps {
  event: EventType;
}

const Wrapper = styled.article`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: rgba(200, 200, 255, 0.08);
  gap: 14px;

  border-radius: 14px;
  padding: 0 30px 30px 30px;
  transition: all 0.35s ease-in-out;
  &:hover {
    -webkit-box-shadow: 0px 0px 69px -13px ${({ theme }) => theme.colors.primary};
    -moz-box-shadow: 0px 0px 69px -13px ${({ theme }) => theme.colors.primary};
    box-shadow: 0px 0px 69px -13px ${({ theme }) => theme.colors.primary};
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  width: 128px;
  height: 128px;
  position: relative;
  top: -30px;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fonts.big};
  color: ${({ theme, color }) => color || theme.colors.text};
  font-weight: bold;
`;

const Description = styled.span`
  font-size: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme, color }) => color || theme.colors.textLightGray};
`;

const Content = styled.div`
  text-align: center;
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const DetailLink = styled(Link)`
  color: white;
  background-color: transparent;
  border: none;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const shortDescription =
    event.description.slice(0, 300) +
    (event.description.length > 300 ? '...' : '');

  const startDate = new Date(event.start_datetime);
  const entryDate = new Date(event.entry_datetime);
  const parsedStartDate = format(startDate);
  const parsedEntryDate = format(entryDate);
  const theme = useTheme();

  return (
    <DetailLink to={`events/${event.id}`}>
      <Wrapper className={'animate bottom-animate'}>
        <ImageWrapper>
          <Image
            src={BASE_URL + event.avatar_url}
            alt={'Awatar wydarzenia ' + event.title}
          />
        </ImageWrapper>
        <Content>
          <Title>{event.title}</Title>
          <Description color={theme.colors.text}>{parsedStartDate}</Description>
          <Description>{shortDescription}</Description>
        </Content>
        <Button color={'primary'}>Zapisz się</Button>
      </Wrapper>
    </DetailLink>
  );
};

export default EventCard;
