import { EventType } from '../util/Api';
import { UserType } from './User';
import { AnyResponseType, RequestType } from './Request';

export interface TicketType {
  id: number;
  event: EventType;
  uuid: string;
  creator: UserType;
  create_datetime: string;
  scanner: UserType | null;
  scan_datetime: string | null;
  response: RequestType;
  status: TicketStatus;
}

export enum TicketStatus {
  UNUSED = 'UNUSED',
  USED = 'USED',
}
