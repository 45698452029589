import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL, EventType, PutEventType } from '../../util/Api';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { RootState } from '../store';

interface EventsState {
  events: EventType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: EventsState = {
  events: [],
  status: 'idle',
};

export const fetchEvents = createAsyncThunk('data/fetchEvents', async () => {
  const requestConfig: AxiosRequestConfig = {
    params: {
      upcoming: true,
      ongoing: true,
      expired: true,
      show_hide: true,
    },
  };
  const response = await axios.get<EventType[]>(
    `${BASE_URL}/events`,
    requestConfig
  );
  return response.data;
});

export const createEvent = createAsyncThunk<
  EventType,
  { axios: AxiosInstance },
  { rejectValue: string }
>('events/createEvent', async ({ axios }, { rejectWithValue }) => {
  try {
    const response = await axios.post<EventType>(`/events`);
    return response.data;
  } catch (error) {
    return rejectWithValue('error');
  }
});

export const deleteEvent = createAsyncThunk<
  number,
  { axios: AxiosInstance; eventId: number },
  { rejectValue: string }
>('events/deleteEvent', async ({ axios, eventId }, { rejectWithValue }) => {
  try {
    await axios.delete<EventType>(`/events/${eventId}`);
    return eventId;
  } catch (error) {
    return rejectWithValue('error');
  }
});

export const putEvent = createAsyncThunk<
  EventType,
  { axios: AxiosInstance; eventId: number; updates: PutEventType },
  { rejectValue: string }
>(
  'events/putEvent',
  async ({ axios, eventId, updates }, { rejectWithValue }) => {
    try {
      const response = await axios.put<EventType>(
        `/events/${eventId}`,
        updates
      );
      return response.data;
    } catch (error) {
      return rejectWithValue('error');
    }
  }
);

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.events.push(action.payload);
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.events = state.events.filter((e) => e.id !== action.payload);
      })
      .addCase(putEvent.fulfilled, (state, action) => {
        state.events = state.events.map((e) =>
          e.id !== action.payload.id ? e : action.payload
        );
      });
  },
});

export default eventsSlice.reducer;

export const selectAllEvents = (state: RootState) => state.events.events;
export const selectEventsStatus = (state: RootState) => state.events.status;

export const selectEventById = (eventId: number) => (state: RootState) => {
  return state.events.events.find((event) => event.id === eventId);
};
