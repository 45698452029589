import styled from 'styled-components';
import NavBar from '../../molecules/navigations/NavBar';
import ContentSection from '../../atoms/sections/ContentSection';
import React from 'react';
import { FieldTitle } from '../../molecules/Texts';

const HeaderBlock = styled.p`
  font-size: ${({ theme }) => theme.fonts.medium};
  font-weight: bold;
  margin-top: 14px;
  margin-bottom: 7px;
`;

const TextBlock = styled.p`
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.textLightGray};
`;

const Wrapper = styled.div`
  margin: 30px 0;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  @media (max-width: 900px) {
    justify-items: start;
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

const AboutView = () => {
  return (
    <ContentSection>
      <NavBar />
      <Wrapper>
        <div>
          <HeaderBlock>Koło Naukowe Analiz Strategicznych</HeaderBlock>
          <TextBlock className={'animate left-animate'}>
            Koło Naukowe Analiz Strategicznych działa przy Uniwersytecie
            Ekonomicznym w Krakowie, w Instytucie Zarządzania przy Katedrze
            Analiz Strategicznych.
          </TextBlock>
          <TextBlock className={'animate left-animate'}>
            Misją organizacji jest rozwijanie kompetencji i umiejętności
            członków w zakresie prowadzenia analiz strategicznych i doradztwa
            organizacyjnego oraz propagowanie wiedzy i dobrych praktyk w tych
            dziedzinach wśród społeczności akademickiej.
          </TextBlock>
          <TextBlock className={'animate left-animate'}>
            Działalność Koła to przede wszystkim: organizacja szkoleń i
            warsztatów dla studentów, organizacja konferencji naukowych, debat i
            seminariów, prowadzenie badań naukowych, organizacja spotkań z
            przedstawicielami biznesu oraz realizacja projektów badawczych i
            opracowywanie raportów we współpracy z przedsiębiorstwami.
          </TextBlock>
        </div>
        <div>
          <HeaderBlock>
            Zarząd Koła Naukowego w roku akademickim 2024/2025
          </HeaderBlock>
          <TextBlock className={'animate left-animate'}>
            <p>Prezes – Tomasz Rak</p>
            <p>Wiceprezes ds. Naukowych – Julia Malik</p>
            <p>Wiceprezes ds. Rozwoju – Filip Słowiński</p>
            <p>Wiceprezes ds. Komunikacji – Aleksandra Macek</p>
            <p>Wiceprezes ds. Finansów – Jakub Ziembiński</p>
            <p>Sekretarz – Aleksandra Pierga</p>
          </TextBlock>
          <HeaderBlock>Opiekun Koła Naukowego</HeaderBlock>
          <TextBlock className={'animate left-animate'}>
            dr inż. Gracjana Noga
          </TextBlock>
        </div>
      </Wrapper>
    </ContentSection>
  );
};

export default AboutView;
