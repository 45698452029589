import styled from 'styled-components';
import React from 'react';
import { FieldDescription, FieldTitle } from '../../../molecules/Texts';
import ClickableText from '../../../atoms/ClickableParagraph';

interface FieldTitleDescriptionProps {
  title: string;
  description: string;
  required: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  gap: 10px;
`;

const RequiredMarker = styled.span`
  font-size: ${({ theme }) => theme.fonts.big};
  color: ${({ theme }) => theme.colors.danger};
  margin-left: 0.5rem;
`;

const FieldTitleDescription: React.FC<FieldTitleDescriptionProps> = ({
  title,
  description,
  required,
}) => {
  return (
    <Wrapper>
      <FieldTitle>
        {title}
        {required && <RequiredMarker>*</RequiredMarker>}
      </FieldTitle>
      <FieldDescription>
        <ClickableText text={description} />
      </FieldDescription>
    </Wrapper>
  );
};

export default FieldTitleDescription;
