import { Props, Wrapper } from './Editable';
import { useEffect, useState } from 'react';
import Input from '../../../../../atoms/Input';
import EditableMenu from './EditableMenu';
import React from 'react';

const dateToLocalString = (date: Date): string => {
  const outDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  outDate.setSeconds(0);
  outDate.setMilliseconds(0);
  return outDate.toISOString().slice(0, -1);
};

const EditableDateInput: React.FC<Props<Date, string>> = ({
  value,
  onSubmit,
}) => {
  const localValue = dateToLocalString(value);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>(localValue);

  useEffect(() => {
    setPrompt(localValue);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = () => {
    setEditMode(false);
    setPrompt(localValue);
    onSubmit(prompt);
  };

  const handleRollback = () => {
    setEditMode(false);
    setPrompt(localValue);
  };

  return (
    <Wrapper>
      <Input
        onChange={handleChange}
        value={prompt}
        disabled={!isEditMode}
        type={'datetime-local'}
        min={'2024-01-01'}
        max={'9999-12-31'}
      />
      <EditableMenu
        isEditing={isEditMode}
        onEdit={() => setEditMode(true)}
        onCancel={handleRollback}
        onSubmit={handleSubmit}
      />
    </Wrapper>
  );
};

export default EditableDateInput;
