import React, { useContext } from 'react';
import Section from '../../../../atoms/sections/Section';
import styled, { ThemeContext } from 'styled-components';
import SectionHeader from '../../../../atoms/SectionHeader';
import Margin from '../../../../atoms/Margin';
import ContentSection from '../../../../atoms/sections/ContentSection';
import EventsContainer from '../../../../molecules/EventsContainer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EventSection = () => {
  const theme = useContext(ThemeContext);
  if (!theme) return null;

  return (
    <Section bgColor={theme.colors.eventArea}>
      <ContentSection>
        <Wrapper>
          <Margin vertical={'100px'} />
          <SectionHeader title={'Aktualne wydarzenia'} />
          <Margin vertical={'100px'} />
          <EventsContainer />
          <Margin vertical={'100px'} />
        </Wrapper>
      </ContentSection>
    </Section>
  );
};

export default EventSection;
