import { useAuth } from '../../AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAxios } from '../../axiosConfig';

const AdminSecure = () => {
  const { token, setToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useAxios();

  const moveToLogin = () => {
    setToken(null);
    navigate('/login');
  };

  useEffect(() => {
    if (!token) {
      moveToLogin();
    } else {
      (async function validateToken() {
        try {
          await axios.get('/login');
        } catch (error) {
          return moveToLogin();
        }
      })();
    }
  }, [navigate, location]);

  return null;
};

export default AdminSecure;
