import React, { useEffect, useState } from 'react';
import { RequestStatusType, RequestType } from '../../../../types/Request';
import RequestsPagination from './RequestsPagination';
import { useSelector } from 'react-redux';
import {
  selectAllEvents,
  selectEventsStatus,
} from '../../../../state/events/eventsSlice';
import { useParams } from 'react-router-dom';
import RequestComponent from './RequestComponent';
import { useTicketId } from '../scanner/Ticket';
import { FieldTitle } from '../../../../molecules/Texts';

interface IProps {
  requestStatusType: RequestStatusType;
}

const RequestPaginationView: React.FC<IProps> = ({ requestStatusType }) => {
  useEffect(() => {}, [requestStatusType]);

  const [currentRequest, setCurrentRequest] = useState<RequestType | null>(
    null
  );
  const [updateKey, setUpdateKey] = useState(1);
  const nextKey = () => {
    setUpdateKey((prevState) => prevState + 1);
  };

  const eventsStatus = useSelector(selectEventsStatus);

  const events = useSelector(selectAllEvents);
  const { eventId: eventIdParam } = useParams();
  const eventId = eventIdParam ? parseInt(eventIdParam) || null : null;
  const event = events.find((e) => e.id === eventId) || null;
  const { ticket, isLoading: ticketLoading } = useTicketId(
    currentRequest ? currentRequest.ticket_id : null
  );

  const error =
    !eventId ||
    (eventsStatus === 'succeeded' && !event) ||
    eventsStatus === 'failed';

  const eventLoading = eventsStatus === 'idle' || eventsStatus === 'loading';

  if (eventLoading) return <p>Pobieranie wydarzenia...</p>;
  if (error) return <p>Błąd...</p>;
  if (!event) return null;

  return (
    <div>
      <FieldTitle>Panel Przeglądania Zgłoszeń</FieldTitle>
      <RequestsPagination
        eventId={event.id}
        setCurrentRequest={setCurrentRequest}
        status={requestStatusType}
        update={updateKey}
      />

      {currentRequest && !ticketLoading && (
        <RequestComponent
          ticket={ticket}
          event={event}
          request={currentRequest}
          onAcceptForm={nextKey}
          onRejectForm={nextKey}
          onEntry={nextKey}
          onDelete={nextKey}
        />
      )}
    </div>
  );
};

export default RequestPaginationView;
