import React, { useContext, useEffect } from 'react';
import './Animate.css';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../theme/ThemeContext';

const Animate = () => {
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error('ThemeContext not found');
  }
  const { colorOption, fontOption } = themeContext;
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          //entry.target.classList.remove('show');
        }
      });
    });

    const observeAnimateElements = (element: HTMLElement) => {
      // Obserwujemy samego elementa, jeśli ma klasę 'animate'
      if (element.classList.contains('animate')) {
        observer.observe(element);
      }
      // Obserwujemy wszystkie potomne elementy z klasą 'animate'
      const childAnimateElements = element.querySelectorAll('.animate');
      childAnimateElements.forEach((child) =>
        observer.observe(child as HTMLElement)
      );
    };

    // MutationObserver do obserwacji nowych elementów dodawanych do DOM
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            observeAnimateElements(node);
          }
        });
      });
    });

    mutationObserver.observe(document.body, {
      childList: true, // Obserwujemy dodanie nowych elementów
      subtree: true, // Obserwujemy również poddrzewa
    });

    const hiddenElements = document.querySelectorAll('.animate');
    hiddenElements.forEach((e) => observer.observe(e));

    return () => {
      hiddenElements.forEach((e) => observer.unobserve(e));
    };
  }, [location, colorOption, fontOption]);

  return <></>;
};

export default Animate;
