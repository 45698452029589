import React from 'react';
import GradientSection from '../../../atoms/sections/GradientSection';
import NavBar from '../../../molecules/navigations/NavBar';
import ContentSection from '../../../atoms/sections/ContentSection';
import styled from 'styled-components';
import Title from '../../../atoms/Title';
import OpeningImage from '../../../atoms/OpeningImage';
import Media from '../../../atoms/MediaItem';
import media from '../../../data/media.json';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 50px;
  }
`;

const ContentField = styled.div`
  width: 50%;
  display: flex;
  @media (max-width: 1200px) {
    width: 100%;
    align-items: center;
  }

  &:first-child {
    justify-content: flex-start;
    @media (max-width: 1200px) {
      justify-content: center;
    }
  }

  &:last-child {
    justify-content: flex-end;
    @media (max-width: 1200px) {
      justify-content: center;
    }
  }
`;

const MediaWrapper = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const MarginWrapper = styled.div`
    margin: 30px 0;
`;

const OpeningSection = () => {
  return (
    <GradientSection>
      <ContentSection>
        <Wrapper>
          <NavBar />
          <Content>
            <ContentFieldWrapper>
              <ContentField>
                <div>
                  <Title>
                    <MarginWrapper>
                      Strategiczne Spotkania w&nbsp;Świecie Biznesu
                    </MarginWrapper>
                    <MarginWrapper>Ogólnopolskie wydarzenie Koła Naukowego Analiz
                      Strategicznych</MarginWrapper>
                  </Title>
                  <MediaWrapper>
                    {media &&
                      media.header.map((media) => (
                        <Media
                          key={media.href}
                          size={'50px'}
                          href={media.href}
                          alt={media.alt}
                          src={media.src}
                        />
                      ))}
                  </MediaWrapper>
                </div>
              </ContentField>
              <ContentField>
                <OpeningImage />
              </ContentField>
            </ContentFieldWrapper>
          </Content>
        </Wrapper>
      </ContentSection>
    </GradientSection>
  );
};

export default OpeningSection;
