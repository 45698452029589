import styled from 'styled-components';
import React from 'react';
import { EventType } from '../../util/Api';
import Element from './Element';
import { format } from '../../js/DatetimeFormat';
import { KeyText, ValueText } from '../../molecules/Texts';
import { SpaceColumnWrapper } from '../../molecules/Wrappers';

interface EventInfoProps {
  event: EventType;
}

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  display: block;
  margin-right: 10px;
`;

const EventInfo: React.FC<EventInfoProps> = ({ event }) => {
  const slots = event.max_capacity - event.current_capacity;

  const parsedStart = format(new Date(event.start_datetime));
  const parsedEnd = format(new Date(event.end_datetime));
  const parsedEntry = format(new Date(event.entry_datetime));

  return (
    <div>
      <Element>
        <SpaceColumnWrapper>
          <Item>
            <Icon src={'/svg/calendar.svg'} alt="Event Icon" />
            <KeyText>
              Rozpoczęcie wydarzenia: <ValueText>{parsedStart}</ValueText>
            </KeyText>
          </Item>
          <Item>
            <Icon src={'/svg/flag.svg'} alt="Event Icon" />
            <KeyText>
              Zakończenie wydarzenia: <ValueText>{parsedEnd}</ValueText>
            </KeyText>
          </Item>
          <Item>
            <Icon src={'/svg/type.svg'} alt="Event Icon" />
            <KeyText>
              Rozpoczęcie składania wniosków:{' '}
              <ValueText>{parsedEntry}</ValueText>
            </KeyText>
          </Item>
        </SpaceColumnWrapper>
      </Element>
      <Element>
        <SpaceColumnWrapper>
          <Item>
            <Icon src={'/svg/user.svg'} alt="Event Icon" />
            <KeyText>
              Dostępne miejsca: <ValueText>{slots}</ValueText>
            </KeyText>
          </Item>
          <Item>
            <Icon src={'/svg/location.svg'} alt="Event Icon" />
            <KeyText>
              Lokalizacja: <ValueText>{event.location}</ValueText>
            </KeyText>
          </Item>
        </SpaceColumnWrapper>
      </Element>
    </div>
  );
};

export default EventInfo;
