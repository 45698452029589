import SmallResponsiveIconButton from '../atoms/SmallResponsiveIconButton';
import { useTheme } from 'styled-components';
import React from 'react';

interface MenuProps {
  isOpen: boolean;
  toggleOpen: React.MouseEventHandler<HTMLButtonElement>;
}

const Menu: React.FC<MenuProps> = ({ isOpen, toggleOpen }) => {
  const theme = useTheme();

  return (
    <SmallResponsiveIconButton
      className={'menu'}
      onClick={toggleOpen}
      bgColor={theme.colors.primary}
      color={theme.colors.invertedText}
      src={'/svg/menu.svg'}
      alt={'menu button'}
      text={'MENU'}
    />
  );
};

export default Menu;
