import styled from 'styled-components';
import React from 'react';
import Button from '../../../../../atoms/Button';

interface EditableMenuProps {
  isEditing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditableMenu: React.FC<EditableMenuProps> = ({
  isEditing,
  onEdit,
  onSubmit,
  onCancel,
}) => (
  <Wrapper>
    {!isEditing && (
      <Button onClick={onEdit}>
        <img src={'/svg/edit.svg'} alt={'cancel icon'} />
      </Button>
    )}
    {isEditing && (
      <>
        <Button color={'success'} onClick={onSubmit} confirm={false}>
          <img src={'/svg/checkbox.svg'} alt={'submit icon'} />
        </Button>
        <Button color={'danger'} onClick={onCancel} confirm={false}>
          <img src={'/svg/cancel.svg'} alt={'cancel icon'} />
        </Button>
      </>
    )}
  </Wrapper>
);

export default EditableMenu;
