import React, { useEffect, useState } from 'react';
import ModalWrapper from '../../../../modals/ModalWrapper';
import { RoleType, UserType } from '../../../../types/User';
import { useAxios } from '../../../../axiosConfig';
import Input from '../../../../atoms/Input';
import Element from '../../../EventView/Element';
import { FieldDescription, FieldTitle } from '../../../../molecules/Texts';
import ToogleButton from '../settings/ToogleButton';
import Button from '../../../../atoms/Button';
import { StyledOption, StyledSelect } from '../../../../atoms/Select';
import { SpaceColumnWrapper } from '../../../../molecules/Wrappers';

interface UserCache {
  id?: number;
  name: string;
  username: string;
  password?: string;
  role_id: number;
  enable: boolean;
}

const AccountEditor = ({
  id,
  updateClose,
}: {
  id: number | null;
  updateClose: () => void;
}) => {
  const initialUserCache: UserCache = {
    name: '',
    username: '',
    password: '',
    role_id: 1,
    enable: true,
  };

  const axios = useAxios();
  const [user, setUser] = useState<UserCache>(initialUserCache);
  const [roles, setRoles] = useState<RoleType[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async function fetch() {
      setIsLoading(true);
      try {
        const response = await axios.get<RoleType[]>('/roles');
        setRoles(response.data);
      } catch (error) {
        console.error('Failed to fetch roles', error);
        setRoles(null);
      }

      if (id) {
        try {
          const response = await axios.get<UserType>(`/users/${id}`);
          const { id: userId, name, username, enable, role } = response.data;
          const roleId = role.id;
          setUser({
            id: userId,
            name,
            username,
            password: '',
            role_id: roleId,
            enable,
          });
        } catch (error) {
          console.error('Failed to fetch user details', error);
        }
      }

      setIsLoading(false);
    })();
  }, [id]);

  const updateCacheUser = (key: string, value: unknown) => {
    setUser((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (user.id) {
      // putUser
      try {
        await axios.put(`/users/${user.id}`, {
          ...(user.name && { name: user.name }),
          ...(user.username && { username: user.username }),
          ...(user.password && { password: user.password }),
          ...(user.role_id && { role_id: user.role_id }),
          enable: user.enable,
        });
        updateClose();
      } catch (error) {
        console.error('Failed to update user', error);
      }
    } else {
      try {
        await axios.post(`/users`, {
          name: user.name,
          username: user.username,
          password: user.password,
          role_id: user.role_id,
          enable: user.enable,
        });
        updateClose();
      } catch (error) {
        console.error('Failed to create user', error);
      }
    }
  };

  return (
    <ModalWrapper>
      <Element>
        <form onSubmit={handleSubmit}>
          <SpaceColumnWrapper>
            <div>
              <FieldTitle>Edytor Konta</FieldTitle>
            </div>
            <div>
              <FieldDescription>Nazwa</FieldDescription>
              <Input
                value={user.name}
                onChange={(e) => updateCacheUser('name', e.target.value)}
              />
            </div>
            <div>
              <FieldDescription>Login</FieldDescription>
              <Input
                value={user.username}
                autoCapitalize={'off'}
                autoComplete={'off'}
                spellCheck={'false'}
                onChange={(e) => updateCacheUser('username', e.target.value)}
              />
            </div>
            <div>
              <FieldDescription>Hasło</FieldDescription>
              <Input
                value={user.password}
                onChange={(e) => updateCacheUser('password', e.target.value)}
              />
            </div>
            <div>
              <FieldDescription>Aktywne konto</FieldDescription>
              <ToogleButton
                checked={user.enable}
                onChange={(state) => updateCacheUser('enable', state)}
              />
            </div>
            <div>
              <FieldDescription>Rola</FieldDescription>
              <StyledSelect
                id="select-option"
                value={user.role_id}
                onChange={(e) =>
                  updateCacheUser('role_id', parseInt(e.target.value))
                }
              >
                {roles &&
                  roles.map((role) => (
                    <StyledOption key={role.id} value={role.id}>
                      {role.name}
                    </StyledOption>
                  ))}
              </StyledSelect>
            </div>
            <div>
              <Button color={'success'} type="submit" confirm={false}>
                Zapisz
              </Button>
              <Button color={'danger'} onClick={updateClose} confirm={false}>
                Anuluj
              </Button>
            </div>
          </SpaceColumnWrapper>
        </form>
      </Element>
    </ModalWrapper>
  );
};

export default AccountEditor;
