import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

interface NavigationItemProps {
  to: string;
  mobile?: boolean;
  children: React.ReactNode;
}

const StyledLink = styled(NavLink)<{ mobile: boolean }>`
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 20px;
  font-weight: normal;
  text-align: center;

  color: ${({ theme }) => theme.colors.text};

  font-size: ${({ theme, mobile }) =>
    mobile ? theme.fonts.big : theme.fonts.normal};

  transition: 0.2s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.textLightGray};
  }

  &.active {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const NavItem = ({ to, mobile = false, children }: NavigationItemProps) => {
  return (
    <StyledLink to={to} mobile={mobile}>
      {children}
    </StyledLink>
  );
};

export default NavItem;
