export const BASE_URL = process.env.REACT_APP_API_URL;

export interface EventType {
  id: number;
  title: string;
  description: string;
  current_capacity: number;
  max_capacity: number;
  avatar_url: string;
  banner_url: string;
  start_datetime: string;
  end_datetime: string;
  entry_datetime: string;
  ongoing_join: boolean;
  hide: boolean;
  lock_join: boolean;
  location: string;
}

export interface PutEventType {
  title?: string;
  description?: string;
  max_capacity?: number;
  avatar_url?: string;
  banner_url?: string;
  start_datetime?: string;
  end_datetime?: string;
  entry_datetime?: string;
  hide?: boolean;
  lock_join?: boolean;
  location?: string;
  ongoing_join?: boolean;
}
