import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../axiosConfig';
import Title from '../atoms/Title';

const Wrapper = styled.div`
  width: 100vw;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
`;

const LoginView = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { token, setToken } = useAuth();
  const axios = useAxios();

  useEffect(() => {
    if (token) {
      navigate('/admin');
    }
  }, [token, navigate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post('/login', {
        username: username,
        password: password,
      });
      setToken(response.data.token);
    } catch (error) {
      setErrorMessage('Nieprawidłowy login lub hasło');
      return;
    }
  };

  return (
    <Wrapper>
      <Title>Panel Administratora</Title>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nazwa użytkownika:</label>
          <Input
            type={'text'}
            name={'login'}
            placeholder={'Nazwa użytkownika'}
            required
            value={username}
            autoCapitalize={'off'}
            autoComplete={'off'}
            spellCheck={'false'}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label>Hasło:</label>
          <Input
            type={'password'}
            name={'password'}
            placeholder={'Hasło'}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Button type={'submit'}>Zaloguj się</Button>
      </form>
    </Wrapper>
  );
};

export default LoginView;
