import React from 'react';
import '../../App.css';
import OpeningSection from './sections/OpeningSection';
import PartnerSection from './sections/PartnerSection';
import EventSection from './sections/EventSection/EventSection';
import Animate from '../../js/Animate';
function MainView() {
  return (
    <div>
      <Animate />
      <OpeningSection />
      <PartnerSection />
      <EventSection />
    </div>
  );
}

export default MainView;
