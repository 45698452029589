import styled from 'styled-components';

export const SpaceColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SpaceRowWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const VerticalMarginWrapper = styled.div`
  margin: 20px 0;
  display: block;
`;

export const MarginWrapper = styled.div`
  margin: 30px 0;
`;
